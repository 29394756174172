import classes from './ShareEvent.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Button from '@lobox/uikit/Button';
import { useGlobalDispatch } from '@lobox/utils';
import { useScheduleDispatch } from '@/shared/contexts/Schedule/schedule.provider';
import type { ISchedulesEvent } from '@lobox/utils';

export interface ShareEventProps {
  className?: string;
  event: ISchedulesEvent;
  label: string;
}

const ShareEvent: React.FC<ShareEventProps> = (props) => {
  const { label, event, className } = props;
  const appDispatch = useGlobalDispatch();
  const scheduleDispatch = useScheduleDispatch();

  const openDetailModal = () => {
    scheduleDispatch({
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
      payload: {
        visibleForm: false,
        schedulesEventType: event.schedulesEventType,
        eventId: event.id,
      },
    });

    appDispatch({
      type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
      payload: {
        isOpen: false,
      },
    });
  };

  const onShareMeeting = () => {
    appDispatch({
      type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
      payload: {
        isOpen: true,
        tabs: ['copy_link', 'share_via_message', 'share_via_email'],
        entityData: {
          attachment: {
            type: event?.schedulesEventType?.toLowerCase(),
            data: { ...event, openDetailModal },
          },
        },
      },
    });
  };

  return (
    <Button
      onClick={onShareMeeting}
      schema={
        event?.schedulesEventType === 'MEETING'
          ? 'semi-transparent'
          : 'primary-blue'
      }
      className={cnj(classes.button, className)}
      leftIcon="share-blank"
      leftType="far"
      leftSize={20}
      fullWidth
      label={label}
    />
  );
};

export default ShareEvent;
