import classes from './ScheduleEventDetailsModal.footer.meeting.module.scss';

import React from 'react';
import { useTranslation } from '@lobox/utils';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import ShareEvent from './partials/ShareEvent';
import type { ISchedulesEvent } from '@lobox/utils';

const ScheduleDetailsModalFooterMeeting: React.FC<{
  event: ISchedulesEvent;
}> = ({ event }) => {
  const { t } = useTranslation();

  return (
    <Flex className={classes.wrapper}>
      <ShareEvent event={event} label={t('share_meeting')} />
      <Button
        leftIcon="sign-in-alt"
        leftType="far"
        label={t('join_meeting')}
        className={classes.button}
      />
    </Flex>
  );
};

export default ScheduleDetailsModalFooterMeeting;
