import React from 'react';
import { useScheduleState } from '@/shared/contexts/Schedule/schedule.provider';
import { schedulesEventValues } from '@lobox/utils';
import ScheduleMeetingDetails from './partials/ScheduleMeetingDetails';
import ScheduleTaskDetails from './partials/ScheduleTaskDetails';
import ScheduleHolidayDetails from './partials/ScheduleHolidayDetails';
import ScheduleReminderDetails from './partials/ScheduleReminderDetails';
import ScheduleBirthDayDetails from './partials/ScheduleBirthDayDetails';

const ScheduleDetailsModalContent: React.FC = () => {
  const { schedulesEventType } = useScheduleState('scheduleCreationModalData');

  if (schedulesEventType === schedulesEventValues.MEETING) {
    return <ScheduleMeetingDetails />;
  }
  if (schedulesEventType === schedulesEventValues.TASK) {
    return <ScheduleTaskDetails />;
  }

  if (schedulesEventType === schedulesEventValues.REMINDER) {
    return <ScheduleReminderDetails />;
  }
  if (schedulesEventType === schedulesEventValues.BIRTHDAY) {
    return <ScheduleBirthDayDetails />;
  }
  if (schedulesEventType === schedulesEventValues.HOLIDAY) {
    return <ScheduleHolidayDetails />;
  }
  return null;
};

export default ScheduleDetailsModalContent;
