import classes from './ScheduleReminderDetails.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import { formatDate, useTranslation } from '@lobox/utils';
import Text from './Text';
import { Time } from '@shared/utils/Time';
import useGetCurrentTimeZone from '@shared/hooks/api-hook/useGetCurrentTimeZone';

const ScheduleReminderDetails: React.FC = () => {
  const { t } = useTranslation();
  const { event } = useSchedulesEvent();

  const { data: userTimezone } = useGetCurrentTimeZone();
  const offsetDifference = event?.timezone?.offset
    ? Number(event?.timezone?.offset) - Number(userTimezone?.offset)
    : 0;
  const dateLabel = formatDate(
    Time.getTimeWithOffset(event?.startTime, offsetDifference)
  );
  const time = event.allDay
    ? `${dateLabel} ${t('to')} ${dateLabel} (${t('all_day')})`
    : Time.getFormTime(event?.startTime, offsetDifference)?.label;

  return (
    <Flex className={classes.scheduleMeetingDetailsRoot}>
      <Text
        className={classes.zeroMarginTop}
        label={t('reminder_title')}
        value={event.title}
      />
      <Text label={t('date')} value={dateLabel} />
      <Text label={t('time')} value={time} />
      {event.description && (
        <Text label={t('description')} value={event.description} isRichText />
      )}
    </Flex>
  );
};

export default ScheduleReminderDetails;
