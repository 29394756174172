import classes from './Editor.module.scss';

import React, { useRef, useEffect, memo } from 'react';
import Flex from '../Flex';
import useTheme from '../utils/useTheme';
import cnj from '../utils/cnj';
import Icon from '../Icon';
import { removeHtmlTagsInstring } from '@lobox/utils';
import 'react-quill/dist/quill.snow.css';

interface Quill {
  getEditor(): any;
}

type RichTextEditorProps = {
  handleChange: (value: string) => void;
  value: string;
  showPlusOnHover: boolean;
  placeholder?: string;
  reactQuill?: any;
  readOnly?: boolean;
  initialFocus?: boolean;
  maxLength?: number;
};

const Editor = ({
  handleChange,
  value = '',
  showPlusOnHover = false,
  placeholder,
  reactQuill,
  readOnly,
  initialFocus = false,
  maxLength,
}: RichTextEditorProps): JSX.Element => {
  const ReactQuill = reactQuill;
  const quillRef = useRef<Quill>();

  const handleFocusOnMe = () => {
    if (quillRef?.current) {
      const quill = quillRef.current.getEditor();
      const lastIndex = quill.getLength() - 1;
      quill.setSelection(lastIndex, 0);
    }
  };

  useEffect(() => {
    if (!readOnly && initialFocus) {
      handleFocusOnMe();
    }
  }, [readOnly, initialFocus]);
  useEffect(() => {
    (quillRef.current as any)?.editor.root.setAttribute('spellcheck', 'false');
  }, []);

  const { isDark } = useTheme();

  function checkMaxLength(e: any) {
    if (!maxLength) return;
    const editor = quillRef.current?.getEditor();
    const innerText = editor?.getText() || '';
    const contentPureLength = removeHtmlTagsInstring(innerText)?.length;
    const isExceeding = contentPureLength > maxLength;
    if (isExceeding && e?.key !== 'Backspace') {
      e?.preventDefault();
    }
  }
  return (
    <>
      {!readOnly && <QuillToolbar />}
      <Flex
        className={cnj(
          classes.quillEditorContainer,
          showPlusOnHover && classes.showPlusOnHover,
          isDark
            ? classes.quillEditorContainerDark
            : classes.quillEditorContainerLight
        )}
      >
        <ReactQuill
          modules={{
            toolbar: !readOnly && {
              container: '#toolbar',
            },
          }}
          formats={formats}
          ref={quillRef}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={readOnly}
          onKeyDown={checkMaxLength}
        />
        <button
          type="button"
          className="click-to-focus"
          style={{ display: 'none' }}
          onClick={handleFocusOnMe}
        >
          Focus
        </button>
      </Flex>
    </>
  );
};

export default Editor;

export const formats = ['bold', 'italic', 'underline', 'list', 'header'];

export const QuillToolbar = memo(() => {
  return (
    <div id="toolbar" className={classes.toolbar}>
      {/* eslint-disable */}
      <span className="ql-formats">
        <button className="ql-header">
          <Icon size={14} name="heading" type="far" />
        </button>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" type="button" />{' '}
        <button className="ql-italic" type="button" />
        <button className="ql-underline" type="button" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="bullet" type="button" />
        <button className="ql-list" value="ordered" type="button" />
      </span>
      {/* eslint-enable */}
    </div>
  );
});
