import classes from './UserItem.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import { useTranslation } from '@lobox/utils';

type Props = {
  title: string;
  username: string;
  croppedImageUrl: string;
};

export const UserItem: React.FC<Props> = ({
  title,
  username,
  croppedImageUrl,
}) => {
  const { t } = useTranslation();

  return (
    <AvatarCard
      noHover
      infoWrapClassName={classes.infoWrapModified}
      data={{
        title,
        subTitle: `@${username}`,
        image: croppedImageUrl,
      }}
      key={username}
      titleProps={{
        color: 'primaryText',
        size: 15,
        font: '400',
        height: 21,
        isTruncated: true,
        className: classes.truncatedText,
      }}
      subTitleProps={{
        color: 'colorIconForth2',
        height: 15,
        size: 13,
        mt: 0,
        isTruncated: true,
        className: classes.truncatedText,
      }}
      avatarProps={{
        size: 'sm',
        isCompany: false,
      }}
      containerProps={{
        className: classes.containerProps,
      }}
    />
  );
};
