import classes from './Collaborators.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import AvatarCard from '@lobox/uikit/AvatarCard';
import { pluralize, useTranslation } from '@lobox/utils';
import type { UserType } from '@lobox/utils';

export interface AttendeeProps {
  className?: string;
  value: Array<UserType>;
  labelKey: string;
}

const Attendee: React.FC<AttendeeProps> = ({ value, className, labelKey }) => {
  const { t } = useTranslation();

  return (
    <Flex className={cnj(className)}>
      <Typography color="colorIconForth2" font="500" size={13} height={15}>
        {pluralize(t(labelKey), value?.length, false)}
      </Typography>
      {value.map(({ id, fullName, usernameAtSign, croppedImageUrl }) => (
        <AvatarCard
          noHover
          key={id}
          infoWrapClassName={classes.infoWrapClassName}
          containerProps={{
            className: classes.container,
          }}
          avatarProps={{
            size: 'sm',
          }}
          titleProps={{
            height: 20,
          }}
          subTitleProps={{
            size: 15,
            height: 21,
            mt: 0,
          }}
          data={{
            image: croppedImageUrl,
            title: fullName,
            subTitle: usernameAtSign,
          }}
        />
      ))}
    </Flex>
  );
};

export default Attendee;
