import classes from './InfoCard.module.scss';

import React from 'react';
import type { ComponentProps, ReactNode } from 'react';
import Icon from '../Icon';
import Flex from '../Flex';
import cnj from '../utils/cnj';
import Typography from '../Typography';

type Props = {
  label?: string;
  leftIconProps?: ComponentProps<typeof Icon>;
  rightIconProps?: ComponentProps<typeof Icon>;
  classNames?: {
    wrapper?: string;
    leftWrapper?: string;
    leftIcon?: string;
    label?: string;
    rightIcon?: string;
  };
  children?: ReactNode;
};

export function InfoCard({
  label = '',
  classNames = {},
  leftIconProps = {},
  rightIconProps = {},
  children,
}: Props): JSX.Element {
  const { name: leftName = 'exclamation-triangle', type: leftType = 'far' } =
    leftIconProps;
  const { name: rightName = 'times', type: rightType = 'far' } = rightIconProps;
  const hasRightIcon = !!Object.keys(rightIconProps)?.length;

  return (
    <Flex className={cnj(classes.wrapper, classNames.wrapper)}>
      <Flex className={cnj(classes.leftWrapper, classNames.leftWrapper)}>
        {leftIconProps && (
          <Icon
            color="pendingOrange"
            type={leftType}
            name={leftName}
            {...leftIconProps}
            className={cnj(classes.leftIcon, classNames.leftIcon)}
          />
        )}

        <Typography className={cnj(classes.label, classNames.label)}>
          {label}
          {children}
        </Typography>
      </Flex>
      {hasRightIcon && (
        <Icon
          {...rightIconProps}
          type={rightType}
          name={rightName}
          className={cnj(classes.rightIcon, classNames.rightIcon)}
        />
      )}
    </Flex>
  );
}
