import { MessageInputActions } from '../../constants';
import type {
  IMessage,
  IChatRoom,
  InputActionType,
} from '@shared/types/messaging';

type ContentTabTypes = 'media' | 'document' | 'link';

type InitialStateProps = {
  variant: 'page' | 'slide';
  isOpenConversation: boolean;
  isOpenContactInfo: boolean;
  activeRoom: IChatRoom | undefined;
  isOpenSearchConversation: boolean;
  isOpenArchive: boolean;
  isOpenStaredMessage: boolean;
  isOpenRightSection: boolean;
  isOpenCreateRoom: boolean;
  isOpenMessageRequest: boolean;
  visibleUnblockModal: boolean;
  selectedMessage?: IMessage;
  messageFile?: any;
  inputAction: InputActionType;
  isOpenedByDefaultActiveRoom?: boolean;
  isOpenGroupInfo: boolean;
  groupInfoForm: { isOpen: boolean; formValues?: Partial<any> };
  isOpenChatList: boolean;
  contactTabs: { isOpen: boolean; activeTab?: ContentTabTypes };
};

export const initialState: InitialStateProps = {
  variant: 'page',
  isOpenConversation: false,
  isOpenContactInfo: false,
  activeRoom: undefined,
  isOpenSearchConversation: false,
  isOpenArchive: false,
  selectedMessage: undefined,
  isOpenRightSection: false,
  isOpenStaredMessage: false,
  isOpenCreateRoom: false,
  isOpenMessageRequest: false,
  messageFile: undefined,
  visibleUnblockModal: undefined,
  inputAction: MessageInputActions.SEND_MESSAGE,
  contactTabs: {
    isOpen: false,
    activeTab: undefined,
  },
  isOpenChatList: true,
  isOpenGroupInfo: false,
  isOpenedByDefaultActiveRoom: false,
  groupInfoForm: { isOpen: false, formValues: undefined },
};

type ActionType =
  | {
      type: 'OPEN_MESSAGE_CONVERSATION';
      payload: { isOpenedByDefaultActiveRoom?: boolean };
    }
  | {
      type: 'SET_ACTIVE_ROOM';
      payload: { room: IChatRoom };
    }
  | {
      type: 'OPEN_SEARCH_CONVERSATION';
      payload: { conversation: any };
    }
  | { type: 'CLOSE_SEARCH_CONVERSATION' }
  | { type: 'OPEN_MESSAGE_LIST' }
  | { type: 'CLOSE_MESSAGE_LIST' }
  | { type: 'OPEN_ARCHIVE' }
  | { type: 'CLOSE_ARCHIVE' }
  | { type: 'OPEN_MESSAGE_REQUEST' }
  | { type: 'CLOSE_MESSAGE_REQUEST' }
  | { type: 'OPEN_CONTACT_INFO'; payload: { contact: any } }
  | { type: 'TOGGLE_RIGHT_SECTION' }
  | { type: 'CLOSE_RIGHT_SECTION' }
  | { type: 'CLOSE_CREATE_ROOM' }
  | { type: 'OPEN_CREATE_ROOM' }
  | { type: 'OPEN_STARED_MESSAGE' }
  | { type: 'CLOSE_STARED_MESSAGE' }
  | { type: 'OPEN_BLOCKED_MODAL' }
  | { type: 'CLOSE_BLOCKED_MODAL' }
  | { type: 'OPEN_GROUP_INFO' }
  | {
      type: 'OPEN_GROUP_INFO_FORM';
      payload: { formValues?: Partial<any> };
    }
  | {
      type: 'OPEN_CONTENT_TABS';
      payload: { activeTab?: ContentTabTypes };
    }
  | { type: 'CLOSE_MESSAGE_CONVERSATION' }
  | { type: 'CLOSE_CONTENT_TABS' }
  | { type: 'CLOSE_CONTACT_INFO' }
  | { type: 'CLOSE_GROUP_INFO' }
  | { type: 'CLOSE_GROUP_INFO_FORM'; payload: { formValues?: Partial<any> } }
  | {
      type: 'MESSAGE_X_ACTION';
      payload: { inputAction: InputActionType; message?: IMessage };
    }
  | { type: 'SET_MESSAGE_FILE'; payload: { file: any } };

type StateType = typeof initialState;

export function messageReducer(
  state: StateType,
  action: ActionType
): StateType {
  switch (action.type) {
    case 'MESSAGE_X_ACTION':
      return {
        ...state,
        selectedMessage: action.payload.message,
        inputAction: action.payload.inputAction,
      };
    case 'OPEN_MESSAGE_CONVERSATION':
      return {
        ...state,
        isOpenedByDefaultActiveRoom:
          action.payload?.isOpenedByDefaultActiveRoom,
        isOpenConversation: true,
        isOpenSearchConversation: false,
        isOpenRightSection: false,
        isOpenGroupInfo: false,
        isOpenContactInfo: false,
        contactTabs: { isOpen: false, activeTab: undefined },
      };
    case 'SET_ACTIVE_ROOM':
      return {
        ...state,
        activeRoom: action.payload.room,
      };
    case 'SET_MESSAGE_FILE': {
      const { file } = action.payload;
      return {
        ...state,
        messageFile: file,
      };
    }
    case 'OPEN_MESSAGE_LIST':
      return {
        ...state,
        isOpenChatList: true,
      };
    case 'CLOSE_MESSAGE_LIST':
      return {
        ...state,
        isOpenChatList: false,
      };

    case 'CLOSE_ARCHIVE':
      return {
        ...state,
        isOpenArchive: false,
      };

    case 'OPEN_MESSAGE_REQUEST':
      return {
        ...state,
        isOpenChatList: false,
        isOpenMessageRequest: true,
      };
    case 'CLOSE_MESSAGE_REQUEST':
      return {
        ...state,
        isOpenMessageRequest: false,
      };
    case 'OPEN_ARCHIVE':
      return {
        ...state,
        isOpenChatList: false,
        isOpenArchive: true,
      };
    case 'OPEN_CREATE_ROOM':
      return {
        ...state,
        isOpenGroupInfo: false,
        isOpenChatList: false,
        isOpenCreateRoom: true,
      };
    case 'CLOSE_CREATE_ROOM':
      return {
        ...state,
        isOpenCreateRoom: false,
      };
    case 'TOGGLE_RIGHT_SECTION':
      return {
        ...state,
        isOpenRightSection: !state.isOpenRightSection,
      };
    case 'CLOSE_RIGHT_SECTION':
      return {
        ...state,
        isOpenRightSection: false,
      };
    case 'OPEN_SEARCH_CONVERSATION':
      return {
        ...state,
        isOpenRightSection: true,
        isOpenSearchConversation: true,
        isOpenContactInfo: false,
        isOpenGroupInfo: false,
        groupInfoForm: { isOpen: false, formValues: undefined },
      };
    case 'CLOSE_SEARCH_CONVERSATION':
      return {
        ...state,
        isOpenSearchConversation: false,
        isOpenRightSection: false,
      };
    case 'OPEN_CONTACT_INFO':
      return {
        ...state,
        isOpenRightSection: true,
        isOpenContactInfo: true,
        isOpenSearchConversation: false,
        contactTabs: { isOpen: false, activeTab: undefined },
        isOpenGroupInfo: false,
        isOpenStaredMessage: false,
      };

    case 'CLOSE_CONTACT_INFO':
      return {
        ...state,
        isOpenContactInfo: initialState.isOpenContactInfo,
      };
    case 'OPEN_STARED_MESSAGE':
      return {
        ...state,
        isOpenChatList: false,
        isOpenStaredMessage: true,
      };
    case 'OPEN_GROUP_INFO':
      return {
        ...state,
        isOpenCreateRoom: false,
        isOpenContactInfo: false,
        isOpenStaredMessage: false,
        isOpenSearchConversation: false,
        isOpenGroupInfo: true,
        isOpenRightSection: true,
      };
    case 'OPEN_GROUP_INFO_FORM':
      return {
        ...state,
        isOpenCreateRoom: false,
        isOpenContactInfo: false,
        isOpenSearchConversation: false,
        groupInfoForm: {
          isOpen: true,
          formValues: action.payload.formValues,
        },
      };
    case 'OPEN_CONTENT_TABS':
      return {
        ...state,
        isOpenRightSection: true,
        isOpenContactInfo: false,
        isOpenSearchConversation: false,
        contactTabs: {
          isOpen: true,
          activeTab: action.payload.activeTab,
        },
      };

    case 'CLOSE_GROUP_INFO':
      return {
        ...state,
        isOpenGroupInfo: false,
      };
    case 'CLOSE_GROUP_INFO_FORM':
      return {
        ...state,
        groupInfoForm: {
          isOpen: false,
          formValues: action.payload?.formValues,
        },
      };
    case 'CLOSE_MESSAGE_CONVERSATION':
      return {
        ...state,
        isOpenConversation: initialState.isOpenConversation,
        activeRoom: initialState.activeRoom,
      };
    case 'CLOSE_CONTENT_TABS':
      return {
        ...state,
        contactTabs: {
          isOpen: false,
          activeTab: undefined,
        },
      };
    case 'CLOSE_STARED_MESSAGE':
      return {
        ...state,
        isOpenChatList: true,
        isOpenStaredMessage: false,
      };
    case 'OPEN_BLOCKED_MODAL':
      return {
        ...state,
        visibleUnblockModal: true,
      };
    case 'CLOSE_BLOCKED_MODAL':
      return {
        ...state,
        visibleUnblockModal: false,
      };
    default: {
      throw new Error(`Unsupported action type at APP Reducer`);
    }
  }
}
