import React, { useEffect, useState, forwardRef } from 'react';
import RichTextComponent from './RichText.component';
import useTheme from '../utils/useTheme';

const RichTextEditor = (props: any, ref: any) => {
  const [isMounted, setIsMounted] = useState(false);
  const { isDark } = useTheme();

  useEffect(() => {
    setIsMounted(true);
  }, []);
  useEffect(() => {
    setIsMounted(false);
    setTimeout(() => setIsMounted(true), 0);
  }, [isDark]);

  const { innerRef } = props;

  if (!isMounted) return null;

  const ReactQuill = require('react-quill');

  return (
    <RichTextComponent
      reactQuill={ReactQuill}
      {...props}
      ref={ref || innerRef}
    />
  );
};

export default forwardRef(RichTextEditor);
