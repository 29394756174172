import * as React from 'react';
const Webex: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#b)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#c)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#d)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#e)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#f)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#g)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#h)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#i)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#j)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#k)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#l)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#m)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#n)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#o)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#p)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#q)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#r)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#s)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#t)"
      d="M5.938 8.077c.257.874.618 1.698 1.03 2.52.153.36.41.67.72.927l.205.103c.515.102.772-.31.978-.67.257-.617.463-1.234.617-1.851.051-.103.051-.206.103-.36.051-.206.103-.463.154-.67l2.264-6.018a5.1 5.1 0 0 1 1.8-1.492A4.712 4.712 0 0 1 16.021 0c1.132 0 2.213.412 3.087 1.08 1.492 1.132 2.367 3.344 2.367 3.344.102.309.205.618.205.926 0 .309-.051.566-.154.875a8.75 8.75 0 0 1-.463.72c-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.308 0-.617-.051-.926-.205a2.996 2.996 0 0 1-.771-.566s0-.052-.052-.052a3.84 3.84 0 0 1-.36-.617 5.618 5.618 0 0 0-.412-.772c-.102-.103-.154-.257-.257-.36-.051-.103-.154-.257-.308-.309-.206-.154-.464-.205-.67-.102l-.05-.052a1.58 1.58 0 0 0-.515.772 18.172 18.172 0 0 0-.772 2.366l-.103.463-.926 3.036-1.286 2.88c-.463.618-1.08 1.08-1.8 1.44-.67.31-1.44.515-2.213.515-3.19.052-4.681-2.83-4.681-2.83-1.8-2.983-2.315-6.893-2.47-8.179-.05-.154-.05-.309-.05-.463 0-.411.102-.772.308-1.132a2.11 2.11 0 0 1 .823-.823c.36-.154.772-.257 1.183-.257.669.051 1.286.36 1.698.926.257.463.411.926.411 1.44.103 1.184.36 2.316.67 3.396Z"
    />
    <path
      fill="url(#u)"
      d="m10.568 5.813 1.44-3.807A5.1 5.1 0 0 1 13.81.514C14.53.206 15.301 0 16.073 0c1.132 0 2.212.412 3.087 1.08 1.491 1.132 2.366 3.344 2.366 3.344.103.309.206.618.206.926 0 .309-.052.566-.154.875-.155.257-.31.514-.463.72-.206.206-.463.36-.72.463a2.403 2.403 0 0 1-.875.154c-.309 0-.618-.051-.926-.205a2.997 2.997 0 0 1-.772-.566s0-.052-.051-.052c-.155-.206-.258-.411-.36-.617-.103-.257-.258-.515-.412-.772-.103-.103-.154-.257-.257-.36-.052-.103-.155-.257-.309-.309-.206-.154-.463-.205-.669-.102l-.103-.052a1.58 1.58 0 0 0-.514.772 18.187 18.187 0 0 0-.772 2.366l-.103.463-.926 3.036-2.778-5.35Z"
    />
    <path
      fill="url(#v)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#w)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#x)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#y)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#z)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#A)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#B)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#C)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#D)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#E)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#F)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#G)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#H)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <path
      fill="url(#I)"
      d="M20.96 2.418c-.154 0-.36.052-.514.052.411.617.771 1.234 1.029 1.903.103.309.205.617.205.926 0 .309-.051.566-.154.875-.154.257-.308.514-.463.72-.206.206-.463.411-.72.463a2.403 2.403 0 0 1-.875.154c-.411 0-.771-.103-1.131-.309l-.206.875c-.154.669-.412 1.286-.72 1.903-.155.36-.36.72-.566 1.08-.155.207-.36.412-.618.515-.103 0-.205.052-.257.052-.103 0-.206-.052-.309-.103-.154-.154-.308-.309-.411-.515a8.381 8.381 0 0 1-.309-.668c-.206-.463-.514-1.647-.514-1.647l-.309-1.234-.309-1.235-.205-.823a9.274 9.274 0 0 0-1.39-3.19C11.598 1.286 10.672.618 9.643.257 8.871 0 7.996-.05 7.173.052 5.733.36 4.498 1.235 3.675 2.47c.206.051.411.154.617.257.257.154.463.36.617.566.258.463.412.926.412 1.44.103 1.08.309 2.212.617 3.241.31-1.183.823-2.263 1.492-3.292l.309-.309c.051-.051.154-.051.206-.051.154 0 .257.051.36.102.051 0 .051.052.051.052l.309.309.463.926c.257.72.463 1.594.463 1.594l.617 2.521c.206.875.515 1.75.875 2.572.977 2.264 2.47 2.881 2.47 2.881.72.36 1.543.618 2.366.618.771.051 1.595-.155 2.263-.463a6.708 6.708 0 0 0 2.007-1.595c.308-.463.617-.926.874-1.44.617-1.184 1.029-2.419 1.39-3.705.205-.771.41-1.594.514-2.418.051-.411.103-.771.154-1.183.051-.36.051-.771-.051-1.132-.052-.051-.052-.102-.052-.205-.206-.463-.514-.875-.977-1.132-.31-.103-.72-.206-1.08-.206Z"
    />
    <defs>
      <radialGradient
        id="b"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(74.325 2.356 12.074) scale(8.35815 6.35065)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#227B60" />
        <stop offset={0.14} stopColor="#227B60" stopOpacity={0.81} />
        <stop offset={0.58} stopColor="#227B60" stopOpacity={0.23} />
        <stop offset={0.78} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="c"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-.11164 5.64875 -4.2932 -.08485 14.426 6.256)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#227B60" />
        <stop offset={0.25} stopColor="#227B60" stopOpacity={0.7} />
        <stop offset={0.71} stopColor="#227B60" stopOpacity={0.2} />
        <stop offset={0.92} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="d"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(.12759 5.266 -3.73823 .09057 14.309 6.037)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#227B60" />
        <stop offset={0.07} stopColor="#227B60" stopOpacity={0.9} />
        <stop offset={0.34} stopColor="#227B60" stopOpacity={0.51} />
        <stop offset={0.59} stopColor="#227B60" stopOpacity={0.23} />
        <stop offset={0.79} stopColor="#227B60" stopOpacity={0.06} />
        <stop offset={0.92} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="e"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(.10525 4.2517 -3.0205 .07477 14.678 6.226)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#227B60" />
        <stop offset={0.36} stopColor="#227B60" stopOpacity={0.63} />
        <stop offset={0.92} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="f"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(88.599 4.013 10.344) scale(3.39154 2.16959)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20735A" />
        <stop offset={0.15} stopColor="#20745A" stopOpacity={0.93} />
        <stop offset={0.38} stopColor="#21755C" stopOpacity={0.74} />
        <stop offset={0.67} stopColor="#21785D" stopOpacity={0.43} />
        <stop offset={1} stopColor="#227B60" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="g"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(23.81 -8.879 39.163) scale(5.45953 4.09286)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#227A61" />
        <stop offset={0.36} stopColor="#227A61" stopOpacity={0.56} />
        <stop offset={0.72} stopColor="#227A61" stopOpacity={0.16} />
        <stop offset={0.9} stopColor="#227A61" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="h"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(5.94219 4.20386 -3.84453 5.43427 13.373 -2.08)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3CD9C3" />
        <stop offset={0.07} stopColor="#3BD7C1" stopOpacity={0.9} />
        <stop offset={0.41} stopColor="#38CEB9" stopOpacity={0.42} />
        <stop offset={0.65} stopColor="#36C8B4" stopOpacity={0.12} />
        <stop offset={0.76} stopColor="#35C6B2" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="i"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(1055.92778 746.87515 -524.11883 740.99618 -11849.4 -16117.1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3CD9C3" />
        <stop offset={0.07} stopColor="#3BD7C1" stopOpacity={0.9} />
        <stop offset={0.41} stopColor="#38CEB9" stopOpacity={0.42} />
        <stop offset={0.65} stopColor="#36C8B4" stopOpacity={0.12} />
        <stop offset={0.76} stopColor="#35C6B2" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="j"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(100.969 4.728 10.574) scale(6.41991 7.93372)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#237F66" stopOpacity={0.8} />
        <stop offset={0.06} stopColor="#2C8C70" stopOpacity={0.65} />
        <stop offset={0.21} stopColor="#3DA785" stopOpacity={0.37} />
        <stop offset={0.35} stopColor="#4ABA93" stopOpacity={0.17} />
        <stop offset={0.47} stopColor="#51C59C" stopOpacity={0.04} />
        <stop offset={0.56} stopColor="#54C99F" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="k"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(-50.816 15.116 -9) scale(8.39033 9.06134)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#167C8A" />
        <stop offset={0.04} stopColor="#167C8A" stopOpacity={0.93} />
        <stop offset={0.33} stopColor="#177F8D" stopOpacity={0.43} />
        <stop offset={0.54} stopColor="#17818F" stopOpacity={0.12} />
        <stop offset={0.65} stopColor="#178290" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="l"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(-31.664 22.224 -16.989) scale(8.1844 6.4672)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.05} stopColor="#177D87" />
        <stop offset={0.11} stopColor="#177E89" stopOpacity={0.8} />
        <stop offset={0.21} stopColor="#177F8B" stopOpacity={0.52} />
        <stop offset={0.32} stopColor="#17818D" stopOpacity={0.29} />
        <stop offset={0.42} stopColor="#17818F" stopOpacity={0.13} />
        <stop offset={0.51} stopColor="#178290" stopOpacity={0.03} />
        <stop offset={0.6} stopColor="#178290" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="m"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(-3.17041 -3.70631 2.91736 -2.49554 10.52 9.005)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.26} stopColor="#177F8A" />
        <stop offset={0.27} stopColor="#177F8A" stopOpacity={0.98} />
        <stop offset={0.38} stopColor="#17808C" stopOpacity={0.68} />
        <stop offset={0.5} stopColor="#17818D" stopOpacity={0.43} />
        <stop offset={0.62} stopColor="#17818F" stopOpacity={0.24} />
        <stop offset={0.74} stopColor="#17828F" stopOpacity={0.11} />
        <stop offset={0.86} stopColor="#178290" stopOpacity={0.03} />
        <stop offset={1} stopColor="#178290" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="n"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(-173.11 4.9 5.04) scale(4.57384)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#13656E" />
        <stop offset={0.11} stopColor="#13646E" stopOpacity={0.73} />
        <stop offset={0.27} stopColor="#12646E" stopOpacity={0.41} />
        <stop offset={0.42} stopColor="#12636E" stopOpacity={0.18} />
        <stop offset={0.55} stopColor="#12636E" stopOpacity={0.05} />
        <stop offset={0.66} stopColor="#12636E" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="o"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(-119.327 7.669 3.17) scale(3.19747 2.68909)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#146A73" />
        <stop offset={0.24} stopColor="#156F79" stopOpacity={0.79} />
        <stop offset={0.45} stopColor="#167783" stopOpacity={0.45} />
        <stop offset={0.65} stopColor="#167D8A" stopOpacity={0.2} />
        <stop offset={0.82} stopColor="#17818E" stopOpacity={0.05} />
        <stop offset={0.95} stopColor="#178290" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="p"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(1.6841 -2.49425 1.97662 1.3346 7.94 9.925)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#198E9C" />
        <stop offset={0.1} stopColor="#198E9C" stopOpacity={0.87} />
        <stop offset={0.41} stopColor="#198E9C" stopOpacity={0.5} />
        <stop offset={0.67} stopColor="#198E9C" stopOpacity={0.23} />
        <stop offset={0.88} stopColor="#198E9C" stopOpacity={0.06} />
        <stop offset={1} stopColor="#198E9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="w"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(98.683 5.7 10.96) scale(10.0572 6.63696)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.4} stopColor="#1A3D9C" stopOpacity={0.42} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="x"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(98.69 9779.025 -5238.812) scale(1237.17 667.071)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.4} stopColor="#1A3D9C" stopOpacity={0.42} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="y"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(98.616 9519.01 -5673.057) scale(1473.4 608.698)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.4} stopColor="#1A3D9C" stopOpacity={0.42} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="z"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(1.05894 8.72669 -6.08866 .73882 16.254 7.337)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.16} stopColor="#1A3D9C" stopOpacity={0.69} />
        <stop offset={0.33} stopColor="#1A3D9C" stopOpacity={0.4} />
        <stop offset={0.49} stopColor="#1A3D9C" stopOpacity={0.18} />
        <stop offset={0.61} stopColor="#1A3D9C" stopOpacity={0.05} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="A"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(2.2391 8.14618 -5.53994 1.52273 17.996 8.466)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18388F" />
        <stop offset={0.37} stopColor="#193B96" stopOpacity={0.45} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="B"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(1.0398 3.78603 -3.59801 .98817 17.14 10.776)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18388F" />
        <stop offset={0.09} stopColor="#183992" stopOpacity={0.78} />
        <stop offset={0.22} stopColor="#193B96" stopOpacity={0.5} />
        <stop offset={0.35} stopColor="#193C98" stopOpacity={0.28} />
        <stop offset={0.47} stopColor="#1A3C9A" stopOpacity={0.13} />
        <stop offset={0.59} stopColor="#1A3D9C" stopOpacity={0.03} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="C"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(1.0398 3.78603 -3.59801 .98817 17.234 11.413)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A3D9C" stopOpacity={0.8} />
        <stop offset={0.01} stopColor="#1A3D9C" stopOpacity={0.78} />
        <stop offset={0.24} stopColor="#1A3D9C" stopOpacity={0.45} />
        <stop offset={0.44} stopColor="#1A3D9C" stopOpacity={0.2} />
        <stop offset={0.6} stopColor="#1A3D9C" stopOpacity={0.05} />
        <stop offset={0.7} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="D"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(67.029 -2.83 10.436) scale(9.37435 6.22151)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.4} stopColor="#1A3D9C" stopOpacity={0.56} />
        <stop offset={0.48} stopColor="#1A3D9C" stopOpacity={0.43} />
        <stop offset={0.69} stopColor="#1A3D9C" stopOpacity={0.12} />
        <stop offset={0.79} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="E"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(1.67133 6.74915 -4.45588 1.10344 6.777 6.626)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A3D9C" />
        <stop offset={0.06} stopColor="#1A3D9C" stopOpacity={0.88} />
        <stop offset={0.21} stopColor="#1A3D9C" stopOpacity={0.61} />
        <stop offset={0.36} stopColor="#1A3D9C" stopOpacity={0.39} />
        <stop offset={0.51} stopColor="#1A3D9C" stopOpacity={0.22} />
        <stop offset={0.67} stopColor="#1A3D9C" stopOpacity={0.1} />
        <stop offset={0.83} stopColor="#1A3D9C" stopOpacity={0.02} />
        <stop offset={1} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="F"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(1.67133 6.74915 -4.45588 1.10344 6.777 6.626)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1B3E9E" />
        <stop offset={0.06} stopColor="#1B3E9E" stopOpacity={0.87} />
        <stop offset={0.21} stopColor="#1B3E9D" stopOpacity={0.61} />
        <stop offset={0.36} stopColor="#1A3D9D" stopOpacity={0.39} />
        <stop offset={0.52} stopColor="#1A3D9C" stopOpacity={0.22} />
        <stop offset={0.67} stopColor="#1A3D9C" stopOpacity={0.1} />
        <stop offset={0.83} stopColor="#1A3D9C" stopOpacity={0.02} />
        <stop offset={1} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="G"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(76.089 -.484 7.817) scale(5.31997 3.5121)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#183991" />
        <stop offset={0.03} stopColor="#183992" stopOpacity={0.95} />
        <stop offset={0.18} stopColor="#193A95" stopOpacity={0.66} />
        <stop offset={0.34} stopColor="#193B97" stopOpacity={0.42} />
        <stop offset={0.5} stopColor="#1A3C99" stopOpacity={0.24} />
        <stop offset={0.66} stopColor="#1A3D9B" stopOpacity={0.1} />
        <stop offset={0.82} stopColor="#1A3D9C" stopOpacity={0.03} />
        <stop offset={1} stopColor="#1A3D9C" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="H"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(.21051 4.19748 -3.6967 .1854 8.403 6.74)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.04} stopColor="#2453D6" stopOpacity={0.6} />
        <stop offset={0.2} stopColor="#2453D6" stopOpacity={0.49} />
        <stop offset={0.52} stopColor="#2453D6" stopOpacity={0.2} />
        <stop offset={0.73} stopColor="#2453D6" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="a"
        x1={1.825}
        x2={19.198}
        y1={10.808}
        y2={3.785}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#1CBBF3" />
        <stop offset={0.34} stopColor="#1F99A5" />
        <stop offset={0.45} stopColor="#209CA6" />
        <stop offset={0.51} stopColor="#24A4AA" />
        <stop offset={0.57} stopColor="#2BB3B0" />
        <stop offset={0.6} stopColor="#30BDB4" />
        <stop offset={0.79} stopColor="#38BA90" />
        <stop offset={1} stopColor="#60F05A" />
      </linearGradient>
      <linearGradient
        id="q"
        x1={2.944}
        x2={10.609}
        y1={-0.366}
        y2={6.728}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.13} stopColor="#2BF9FF" />
        <stop offset={0.27} stopColor="#29F9FF" stopOpacity={0.64} />
        <stop offset={0.4} stopColor="#27FAFF" stopOpacity={0.3} />
        <stop offset={0.49} stopColor="#26FAFF" stopOpacity={0.08} />
        <stop offset={0.54} stopColor="#26FAFF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="r"
        x1={-1.397}
        x2={7.555}
        y1={11.216}
        y2={8.68}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#26FAFF" />
        <stop offset={0.06} stopColor="#26FAFF" stopOpacity={0.88} />
        <stop offset={0.24} stopColor="#26FAFF" stopOpacity={0.51} />
        <stop offset={0.39} stopColor="#26FAFF" stopOpacity={0.23} />
        <stop offset={0.5} stopColor="#26FAFF" stopOpacity={0.06} />
        <stop offset={0.55} stopColor="#26FAFF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="s"
        x1={0.975}
        x2={11.064}
        y1={10.912}
        y2={7.311}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21DEFF" />
        <stop offset={0.04} stopColor="#21DEFF" stopOpacity={0.88} />
        <stop offset={0.17} stopColor="#21DEFF" stopOpacity={0.51} />
        <stop offset={0.27} stopColor="#21DEFF" stopOpacity={0.23} />
        <stop offset={0.34} stopColor="#21DEFF" stopOpacity={0.06} />
        <stop offset={0.38} stopColor="#21DEFF" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="t"
        x1={10.571}
        x2={16.215}
        y1={5.69}
        y2={14.894}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.62} stopColor="#21DEFF" stopOpacity={0} />
        <stop offset={0.66} stopColor="#21DEFF" stopOpacity={0.06} />
        <stop offset={0.73} stopColor="#21DEFF" stopOpacity={0.23} />
        <stop offset={0.83} stopColor="#21DEFF" stopOpacity={0.51} />
        <stop offset={0.96} stopColor="#21DEFF" stopOpacity={0.88} />
        <stop offset={1} stopColor="#21DEFF" />
      </linearGradient>
      <linearGradient
        id="u"
        x1={16.834}
        x2={15.949}
        y1={9.139}
        y2={4.807}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#44B044" />
        <stop offset={0.11} stopColor="#44B044" stopOpacity={0.8} />
        <stop offset={0.27} stopColor="#44B044" stopOpacity={0.52} />
        <stop offset={0.43} stopColor="#44B044" stopOpacity={0.29} />
        <stop offset={0.58} stopColor="#44B044" stopOpacity={0.13} />
        <stop offset={0.7} stopColor="#44B044" stopOpacity={0.03} />
        <stop offset={0.8} stopColor="#44B044" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="v"
        x1={4.462}
        x2={23.147}
        y1={4.63}
        y2={10.814}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3066FB" />
        <stop offset={0.38} stopColor="#2F64F7" />
        <stop offset={0.74} stopColor="#2857D9" />
        <stop offset={1} stopColor="#3C7EFF" />
      </linearGradient>
      <linearGradient
        id="I"
        x1={16.609}
        x2={11.886}
        y1={-2.974}
        y2={8.688}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3774FF" />
        <stop offset={0.05} stopColor="#3774FF" stopOpacity={0.91} />
        <stop offset={0.16} stopColor="#3774FF" stopOpacity={0.66} />
        <stop offset={0.32} stopColor="#3774FF" stopOpacity={0.28} />
        <stop offset={0.43} stopColor="#3774FF" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default Webex;
