import * as React from 'react';
const GoogleMeet: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#00832D"
        d="m12.446 9 2.145 2.438 2.883 1.832.503-4.255-.503-4.16-2.939 1.61L12.445 9Z"
      />
      <path
        fill="#0066DA"
        d="M0 12.875V16.5c0 .829.675 1.5 1.509 1.5h3.645l.755-2.74-.755-2.385-2.501-.75-2.653.75Z"
      />
      <path
        fill="#E94235"
        d="M5.154 0 0 5.125l2.653.75 2.501-.75.742-2.353L5.154 0Z"
      />
      <path fill="#2684FC" d="M5.154 5.125H0v7.75h5.154v-7.75Z" />
      <path
        fill="#00AC47"
        d="m20.768 2.17-3.294 2.685v8.415l3.31 2.697a.755.755 0 0 0 1.219-.592V2.75c0-.634-.74-.981-1.235-.58ZM12.446 9v3.875H5.154V18h10.812c.833 0 1.508-.671 1.508-1.5v-3.23L12.446 9Z"
      />
      <path
        fill="#FFBA00"
        d="M15.966 0H5.154v5.125h7.292V9l5.028-4.143V1.5c0-.829-.675-1.5-1.508-1.5Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h22v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default GoogleMeet;
