import classes from './CelebrationForm.module.scss';

import React, { useState } from 'react';
import Button from '@lobox/uikit/Button';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import Flex from '@lobox/uikit/Flex';
import Form from '@lobox/uikit/Form';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import cnj from '@lobox/uikit/utils/cnj';
import useToast from '@lobox/uikit/Toast/useToast';
import {
  ISchedulesEvent,
  SchedulesEventTypes,
  schedulesApi,
  useTranslation,
  uuId,
} from '@lobox/utils';
import sendWSMessage from '@shared/utils/sendWSMessage';
import AddRecipientsModal from '@shared/components/Organism/ShareEntityTabbedModal/tabs/ShareEntityViaMessage/AddRecipientsModal';
import { ModalFooter } from '@shared/components/molecules';
import Avatars from '@shared/components/Organism/ShareEntityModal/component/Avatars';
import { messageTypes } from '@shared/components/Organism/Message/constants';
import { useGetMessageObject } from '@shared/components/Organism/Message/hooks';

type Props = {
  classNames?: {
    container?: string;
  };
  event: ISchedulesEvent;
  closeHandler: () => void;
  schedulesEventType: SchedulesEventTypes;
};

const CelebrationForm: React.FC<Props> = ({
  classNames,
  event,
  closeHandler,
  schedulesEventType,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { authObject } = useGetMessageObject();
  const [isAddRecipientsModalOpen, setIsAddRecipientsModalOpen] =
    useState(false);
  const onCloseAddRecipientsModal = () => {
    setIsAddRecipientsModalOpen(false);
  };
  const openAddRecipientsModal = () => {
    setIsAddRecipientsModalOpen(true);
  };

  function handleSuccess(data, values) {
    // const wsMessage = {
    //   text: values?.text,
    //   type: schedulesEventType,
    //   id: uuId().create(),
    //   to: selectedUser?.id,
    //   uid: authObject.id,
    //   data: { eventId: 'temp' },
    // };
    // sendWSMessage(wsMessage);
    toast({
      type: 'success',
      icon: 'check-circle',
      title: t('celebrated_successfully'),
      message: t('celebration_message_sent_successfully'),
    });

    closeHandler();
  }

  const isBirthday = schedulesEventType === 'BIRTHDAY';

  return (
    <Form
      initialValues={{}}
      onSuccess={handleSuccess}
      className={cnj(classes.form, classNames?.container)}
      apiFunc={
        isBirthday
          ? schedulesApi.celebrateBirthday
          : schedulesApi.celebrateHoliday
      }
      transform={({ users, ...rest }) => ({
        ...rest,
        id: event?.id,
        ...(!isBirthday && { userIds: users?.map((user) => user?.id) }),
      })}
    >
      {({ values }) => (
        <>
          <AddRecipientsModal
            value={values?.users}
            onChange={console.log}
            isOpen={isAddRecipientsModalOpen}
            onClose={onCloseAddRecipientsModal}
            modalDialogClassName={classes.modal}
          />
          <Flex className={classes.filler}>
            <DynamicFormBuilder
              className={classes.formRoot}
              groups={[
                {
                  name: 'message',
                  cp: 'richtext',
                  required: true,
                  label: isBirthday
                    ? t('send_birthday_message')
                    : t('send_holiday_greetings'),
                  showEmoji: false,
                  className: classes.richtext,
                },
              ]}
            />
            <ModalFooter className={classes.footer}>
              {!!values?.users?.length && (
                <Avatars
                  onClick={openAddRecipientsModal}
                  className={classes.avatars}
                  avatars={values?.users || []}
                />
              )}
              <Flex className={classes.buttonsContainer}>
                {isBirthday ? (
                  <Button
                    schema="ghost"
                    label={t('cancel')}
                    onClick={closeHandler}
                    fullWidth
                  />
                ) : (
                  <Button
                    leftIcon={values?.users?.length ? 'pen' : 'plus'}
                    leftType="far"
                    labelFont="bold"
                    schema="gray"
                    label={
                      values?.users?.length
                        ? t('edit_recipients')
                        : t('add_recipients')
                    }
                    onClick={openAddRecipientsModal}
                    fullWidth
                  />
                )}

                {(!!values?.users?.length || isBirthday) && (
                  <SubmitButton
                    leftIcon={isBirthday ? undefined : 'paper-plane'}
                    leftType="far"
                    labelFont="bold"
                    label={t('send')}
                    fullWidth
                  />
                )}
              </Flex>
            </ModalFooter>
          </Flex>
        </>
      )}
    </Form>
  );
};

export default CelebrationForm;
