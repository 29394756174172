import classes from './Attachments.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import AttachmentPicker from '@lobox/uikit/AttachmentPicker';
import { schedulesApi, useTranslation } from '@lobox/utils';

export interface TextProps {
  value?: any[];
}

const Attachments: React.FC<TextProps> = ({ className, value = [] }) => {
  const { t } = useTranslation();
  if (!value?.length) return null;

  return (
    <Flex className={cnj(classes.textRoot, className)}>
      <Typography
        color="colorIconForth2"
        font="500"
        size={13}
        height={15}
        mb={12}
      >
        {t('attachments')}
      </Typography>
      <AttachmentPicker
        getApi={schedulesApi.getFile}
        value={value}
        readonly
        wrapperClassName={classes.wrapperClassName}
      />
    </Flex>
  );
};

export default Attachments;
