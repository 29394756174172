import classes from './RichText.component.module.scss';

import React, { forwardRef, useMemo } from 'react';
import { removeHtmlTagsInstring, useTranslation } from '@lobox/utils';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import type { TextProps } from '../Typography';
import type { FieldProps } from 'formik';
import Editor from './Editor';
import Flex from '../Flex';
import useTheme from '../utils/useTheme';
import cnj from '../utils/cnj';
import Typography from '../Typography';

type RichTextEditorProps = {
  changeWithDebounce?: boolean;
  onChange?: (text: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement> | boolean) => void;
  value?: number | string;
  helperText?: string | React.ReactNode;
  label?: string;
  style?: string;
  labelStyle?: string;
  inputWrapClassName?: string;
  withRightIconClassName?: string;
  inputStyle?: string;
  placeholder?: string;
  type?: string;
  error?: string;
  rightIcon?: React.ReactNode;
  beforeInput?: React.ReactNode;
  disabled?: boolean;
  bordered?: boolean;
  isFocus?: boolean;
  showStrength?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => any;
  trim?: boolean;
  editable?: boolean;
  setStatus?: Function;
  status?: any;
  name?: string;
  maxLength?: number;
  disabledRightIconPropagation?: boolean;
  formikFieldProps?: FieldProps;
  visibleChangeSecure?: boolean;
  autoComplete?: string;
  wrapperId?: string;
  rightIconClassName?: string;
  isAutoFillAble?: boolean;
  visibleCharCounter?: boolean;
  labelProps?: TextProps;
  reactQuill?: any;
  readOnly?: boolean;
  title?: string;
  editorStyle?: string;
  initialFocus?: boolean;
  showTitle?: boolean;
  classNames?: {
    wrapper?: string;
  };
};

const RichTextEditor = (
  {
    showTitle,
    changeWithDebounce,
    onChange,
    value,
    helperText,
    style,
    placeholder,
    error: parentError,
    onBlur,
    onFocus,
    name,
    maxLength,
    wrapperId,
    visibleCharCounter,
    reactQuill,
    readOnly = false,
    title,
    editorStyle,
    initialFocus,
    classNames,
  }: RichTextEditorProps,
  ref: any
): JSX.Element => {
  const error = parentError;
  const { t } = useTranslation();

  const onChangeWithDebounce = debounce(
    (inputValue) => onChange?.(inputValue),
    250
  );

  function handleStateChange(currentState) {
    if (isEqual(currentState, value)) {
      return '';
    }
    if (onChange) {
      if (changeWithDebounce) {
        return onChangeWithDebounce(currentState);
      }
      onChange(currentState);
    }
    return '';
  }

  const pureText = useMemo(() => removeHtmlTagsInstring(value || ''), [value]);

  function handleChange(editorValue = '') {
    const pureEditorValue = removeHtmlTagsInstring(editorValue);

    if (pureEditorValue.length > 2000)
      if (
        pureText.length >= maxLength &&
        pureEditorValue.length > pureText.length
      )
        return;
    handleStateChange(editorValue);
  }

  const wrapperProps = wrapperId ? { id: wrapperId } : {};
  const { isDark } = useTheme();

  return (
    <Flex
      onBlur={onBlur}
      onFocus={onFocus}
      input
      name={name}
      className={cnj(classes.wrapperComponent, classNames?.wrapper)}
      ref={ref}
    >
      <Flex
        {...wrapperProps}
        className={cnj(classes.inputRoot, classes.editorContainer, style)}
      >
        {title && showTitle && (
          <Flex>
            <Typography className={isDark ? classes.titleDark : classes.title}>
              {title}
            </Typography>
          </Flex>
        )}

        <Editor
          initialFocus={initialFocus}
          reactQuill={reactQuill}
          value={value}
          placeholder={placeholder || ''}
          handleChange={(val: string) => handleChange(val)}
          className={editorStyle}
          readOnly={readOnly}
          maxLength={maxLength}
        />
      </Flex>
      <Flex className={classes.helperWrap}>
        {(error || helperText) && (
          <Typography
            size={13}
            height={15}
            color="border"
            className={cnj(classes.helperText, error && classes.errorText)}
          >
            {t(error as string) || helperText}
          </Typography>
        )}
        {visibleCharCounter && (
          <Typography
            size={13}
            height={15}
            color="border"
            className={classes.maxLength}
          >{`${pureText?.length || 0}/${maxLength}`}</Typography>
        )}
      </Flex>
    </Flex>
  );
};

export default forwardRef(RichTextEditor);
