import classes from './ScheduleBirthDayDetails.module.scss';

import React from 'react';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import useToast from '@lobox/uikit/Toast/useToast';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import { useTranslation, uuId } from '@lobox/utils';
import CelebrateBirthday from '@shared/svg/SvgCelebrateBirthday';
import { messageTypes } from '@shared/components/Organism/Message/constants';
import sendWSMessage from '@shared/utils/sendWSMessage';
import { useGetMessageObject } from '@shared/components/Organism/Message/hooks';
import AdvancedAvatarCard from '@shared/components/molecules/AdvancedAvatarCard';

const ScheduleBirthDayDetails: React.FC = () => {
  const { t } = useTranslation();
  const { event, closeHandler } = useSchedulesEvent();
  const { authObject } = useGetMessageObject();
  const toast = useToast();

  const sendMessageHandler = ({ text }) => {
    const wsMessage = {
      text,
      type: messageTypes.MESSAGE_TYPE_TEXT,
      id: uuId().create(),
      to: event.user?.id,
      uid: authObject.id,
      data: { eventId: event.id },
    };
    sendWSMessage(wsMessage);
    toast({
      type: 'success',
      icon: 'check-circle',
      message: t('yr_msg_sent_success'),
    });
    closeHandler();
  };

  return (
    <Flex className={classes.scheduleMeetingDetailsRoot}>
      <Flex>
        <CelebrateBirthday className={classes.svg} />
        <Divider className={classes.divider} />
      </Flex>
      <AdvancedAvatarCard
        firstText={event?.user?.fullName}
        secondText={event?.user?.usernameAtSign}
        thirdText={event?.user?.occupation?.label}
        avatarProps={{ size: 'slg', imgSrc: event?.user?.croppedImageUrl }}
      />
    </Flex>
  );
};

export default ScheduleBirthDayDetails;
