import classes from './AttendeePicker.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Button from '@lobox/uikit/Button';
import Form from '@lobox/uikit/Form';
import Typography from '@lobox/uikit/Typography';
import useTheme from '@lobox/uikit/utils/useTheme';
import CheckBox from '@lobox/uikit/CheckBox';
import AddRecipientsModal from '@shared/components/Organism/ShareEntityTabbedModal/tabs/ShareEntityViaMessage/AddRecipientsModal';
import { UserItem } from './UserItem';

export interface AttendeePickerComponentProps {
  className?: string;
  leftLabel: string;
  buttonLabel: string;
  buttonIcon?: string;
  onChange: (v: any) => void;
  isOpen: boolean;
  onToggle: () => void;
  value: Array<any>;
  accessLabel?: string;
  creator?: Required<{
    username?: string;
    name?: string;
    surame?: string;
    croppedImageUrl?: string;
  }>;
  permissionValue?: string;
}

const AttendeePickerComponent: React.FC<AttendeePickerComponentProps> = ({
  className,
  onChange,
  value: users = [],
  isOpen,
  onToggle,
  leftLabel,
  buttonLabel,
  buttonIcon = 'pen',
  accessLabel,
  creator,
  permissionValue,
}) => {
  const { isDark } = useTheme();

  const onSuccess = ({ users: v }) => {
    onChange(v);
  };

  const usersAndCreator = !!creator?.username ? [creator, ...users] : users;

  const onChangePermission = (user: any) => () => {
    const hasPermission = user?.permissions?.includes(permissionValue);
    const _users = JSON.parse(JSON.stringify(users));
    const index = _users?.findIndex(
      (item) => item?.username === user?.username
    );
    _users[index].permissions = hasPermission ? [] : [permissionValue];

    onChange(_users);
  };

  return (
    <Form local initialValues={{ users }} onSuccess={onSuccess}>
      {({ values, submitForm }) => (
        <Flex className={cnj(classes.attendeePickerComponentRoot, className)}>
          <Flex className={classes.titleWrapper}>
            <Typography color="smoke_coal" font="500" size={16} height={22}>
              {leftLabel}
            </Typography>
            <Typography color="border" font="500" size={14} height={16}>
              {accessLabel}
            </Typography>
          </Flex>
          <AddRecipientsModal
            title={buttonLabel}
            visibleTab={false}
            isOpen={isOpen}
            onClose={() => {
              submitForm();
              onToggle();
            }}
            value={values?.users}
          />
          {usersAndCreator?.length > 0 && (
            <Flex className={classes.usersWrapper}>
              {usersAndCreator?.map((user) => {
                const isCreator = user?.username === creator?.username;
                const isActive =
                  isCreator || user?.permissions?.includes(permissionValue);
                return (
                  <Flex className={classes.userItem} key={user?.username}>
                    <UserItem
                      title={
                        user?.name
                          ? `${user?.name} ${user?.surname}`
                          : user?.fullName
                      }
                      username={user?.username}
                      croppedImageUrl={user?.croppedImageUrl}
                    />
                    {accessLabel && (
                      <CheckBox
                        defaultSchema="gray"
                        value={isActive}
                        onChange={onChangePermission(user)}
                        disabled={isCreator}
                      />
                    )}
                  </Flex>
                );
              })}
            </Flex>
          )}
          <Button
            className={classes.button}
            onClick={onToggle}
            schema={isDark ? 'dark-gray' : 'light-gray'}
            label={buttonLabel}
            leftIcon={buttonIcon}
            leftType="far"
          />
        </Flex>
      )}
    </Form>
  );
};

export default AttendeePickerComponent;
