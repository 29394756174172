import classes from './AddRecipientsModal.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import {
  ModalBody,
  ModalDialog,
  ModalFooter,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import { useTranslation } from '@lobox/utils';
import Tabs from '@shared/components/containers/Tabs';
import { getChatRoomsList } from '@shared/components/Organism/Message/utils/api/room';
import type { UserType } from '@lobox/utils';

interface AddRecipientsModalProps {
  onChange: (users: Array<UserType>) => void;
  value: Array<UserType>;
  onClose: () => void;
  isOpen: boolean;
  modalDialogClassName: string;
  title?: string;
  visibleTab?: boolean;
}

const MAX = 10;

const AddRecipientsModal: React.FC<AddRecipientsModalProps> = ({
  title,
  value,
  onClose,
  isOpen,
  modalDialogClassName,
  visibleTab,
}) => {
  const { t } = useTranslation();
  const countLabel = `${value?.length ?? 0}/${MAX}`;
  const { isMoreThanTablet } = useMedia();

  return (
    <ModalDialog
      modalDialogClassName={modalDialogClassName}
      isOpen={isOpen}
      onBack={onClose}
      onClose={onClose}
    >
      <ModalHeaderSimple
        visibleHeaderDivider
        title={title || t('add_recipients')}
        hideBack={!isMoreThanTablet}
        noCloseButton={isMoreThanTablet}
      />
      <ModalBody className={classes.modalBody}>
        <Tabs
          tabs={[
            {
              path: 'people',
              title: t('people'),
              width: 117,
              center: true,
              titleClass: classes.titleClass,
              content: () => (
                <DynamicFormBuilder
                  className={classes.grow}
                  groups={[
                    {
                      wrapStyle: visibleTab ? classes.formItem : undefined,
                      name: 'users',
                      cp: 'userPicker',
                      required: true,
                      // listTitle: t('top_suggestions'),
                      placeholder: t('search'),
                      isMultiSelect: true,
                      max: MAX,
                      hideCounter: true,
                    },
                  ]}
                />
              ),
            },
            {
              path: 'group_chats',
              title: t('group_chats'),
              width: 117,
              center: true,
              titleClass: classes.titleClass,
              content: () => (
                <DynamicFormBuilder
                  className={classes.grow}
                  groups={[
                    {
                      wrapStyle: classes.formItem,
                      name: 'users',
                      cp: 'userPicker',
                      required: true,
                      // listTitle: t('top_suggestions'),
                      placeholder: t('search'),
                      isMultiSelect: true,
                      isSearchRoom: true,
                      searchRoomsFunc: getChatRoomsList,
                      max: MAX,
                      hideCounter: true,
                    },
                  ]}
                />
              ),
            },
          ]}
          styles={{
            linksRoot: visibleTab ? classes.linksRoot : classes.hiddeTab,
            content: classes.grow,
            tabsRoot: classes.grow,
          }}
        />
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Typography
          className={classes.countText}
          color="gray"
          font="400"
          size={13}
          height={15}
        >
          {countLabel}
        </Typography>
        <Button
          className={classes.done}
          disabled={!value?.length}
          onClick={onClose}
          label={t('done')}
        />
      </ModalFooter>
    </ModalDialog>
  );
};

export default AddRecipientsModal;
