import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import { useTranslation } from '@lobox/utils';
import Text from './Text';

export interface TextProps {
  className?: string;
  label: string;
  value: string;
  isRichText?: boolean;
}

const OnSiteAddress: React.FC<TextProps> = ({ value, className }) => {
  const { t } = useTranslation();
  const location = value?.[0]?.location?.label;
  const label = t('on_site_Location');

  const _value = location?.[0]?.location?.label;

  return (
    <Flex>
      <Text
        label={label}
        value={_value}
        isRichText={false}
        className={className}
      />
      <BaseButton>
        <Typography color="brand" mt={4} height={18.2} size={14}>
          {t('view_on_map')}
        </Typography>
      </BaseButton>
    </Flex>
  );
};

export default OnSiteAddress;
