import { geoApi, lookupApi, QueryKeys, useReactQuery } from '@lobox/utils';
import { Time } from '@shared/utils/Time';

export type CurrentTimeZone = {
  code: string;
  id: string;
  label: string;
  name: string;
  offset: string;
  [x: string]: string;
};

const useGetCurrentTimeZone = (config = {}) =>
  useReactQuery<CurrentTimeZone>({
    action: {
      key: QueryKeys.getCurrentTimeZone,
      apiFunc: geoApi.getTimeZone,
      params: { code: Time.getTimezoneCode() },
    },
    config,
  });

export default useGetCurrentTimeZone;
