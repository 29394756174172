import classes from './NavLink.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Typography from '@lobox/uikit/Typography';
import useCssVariables from '@shared/hooks/useCssVariables';

interface NavLinkProps {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  width?: number;
  onClick: () => void;
  isActive?: boolean;
  center?: boolean;
  titleClass?: string;
  subText?: string | number;
  isFullWidth?: boolean;
  isFirstLink?: boolean;
}

const NavLink: React.FC<NavLinkProps> = ({
  isActive,
  onClick,
  title,
  width,
  icon,
  center,
  titleClass,
  subText,
  isFullWidth,
  isFirstLink,
}) => {
  const styles = useCssVariables({
    scope: classes.container,
    variables: {
      width,
    },
  });

  return (
    <span
      className={cnj(
        classes.container,
        isFullWidth && classes.fullWidthContainer
      )}
    >
      {styles}
      <BaseButton
        className={cnj(
          classes.link,
          isFirstLink && classes.isFirstLink,
          isFullWidth && classes.fullWidthLink,
          isActive && classes.activeLink,
          center && classes.centerItem,
          titleClass && titleClass
        )}
        onClick={onClick}
      >
        <span className={classes.NavLinkInnerContent}>
          {icon} {title}
          {subText && (
            <Typography font="400" size={14} color="disabledGrayDark">
              {subText}
            </Typography>
          )}
        </span>
      </BaseButton>
    </span>
  );
};

export default NavLink;
