import * as React from 'react';
const Zoom: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#518EF7"
        fillRule="evenodd"
        d="M1.994.043c.194-.037.392-.04.589-.042 4.32.002 8.642 0 12.961.002a2.42 2.42 0 0 1 2.452 2.449v12.573c-.005.337.02.678-.045 1.011a2.412 2.412 0 0 1-1.304 1.71c-.353.179-.75.255-1.143.251-4.162.002-8.325 0-12.487 0-.434-.003-.88.029-1.3-.107A2.417 2.417 0 0 1 .09 16.214c-.108-.372-.083-.764-.086-1.148V2.452A2.41 2.41 0 0 1 1.994.043Zm2.132 5.93a.763.763 0 0 0-.543.712c-.004 1.15-.001 2.3-.001 3.449-.004.297.057.597.188.863.302.64.99 1.07 1.697 1.06 1.731.002 3.462.002 5.193 0a.763.763 0 0 0 .764-.74c.004-1.107 0-2.213.002-3.32a2.174 2.174 0 0 0-.097-.769 1.883 1.883 0 0 0-1.761-1.285c-1.689-.004-3.378 0-5.067-.002-.124 0-.253-.005-.375.032Zm9.778-.005a.335.335 0 0 0-.126.084c-.573.547-1.142 1.098-1.719 1.642a.622.622 0 0 0-.178.474c0 .626-.002 1.254 0 1.881a.618.618 0 0 0 .222.473c.566.483 1.13.969 1.696 1.452.218.197.61.027.617-.267.009-1.746 0-3.493.005-5.24 0-.115.009-.24-.06-.34a.383.383 0 0 0-.457-.159Z"
        clipRule="evenodd"
      />
      <path
        fill="#FEFEFE"
        d="M4.127 5.973c.121-.037.25-.032.374-.032 1.69.002 3.378-.002 5.067.002a1.883 1.883 0 0 1 1.761 1.286c.086.245.104.51.098.768-.002 1.107.001 2.213-.002 3.32a.763.763 0 0 1-.764.741H5.467a1.887 1.887 0 0 1-1.885-1.924c0-1.15-.002-2.3.002-3.449a.763.763 0 0 1 .543-.712ZM13.905 5.968a.383.383 0 0 1 .457.159c.068.1.058.226.06.34-.005 1.748.003 3.494-.005 5.24-.009.294-.4.464-.617.268-.566-.484-1.13-.97-1.697-1.453a.618.618 0 0 1-.222-.473c-.002-.626 0-1.254 0-1.88a.622.622 0 0 1 .178-.475c.577-.544 1.146-1.094 1.72-1.642a.335.335 0 0 1 .126-.084Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Zoom;
