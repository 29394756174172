import * as React from 'react';
const MicrosoftTeams: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#5059C9"
        d="M13.95 6.75h5.166c.488 0 .884.383.884.855v4.553c0 1.735-1.454 3.142-3.248 3.142h-.015c-1.794 0-3.248-1.406-3.249-3.142V7.197c0-.247.207-.447.462-.447ZM17.442 5.85c1.156 0 2.093-.907 2.093-2.025 0-1.119-.937-2.025-2.093-2.025s-2.093.906-2.093 2.025c0 1.118.937 2.025 2.093 2.025Z"
      />
      <path
        fill="#7B83EB"
        d="M10.93 5.85c1.67 0 3.023-1.31 3.023-2.925C13.953 1.31 12.6 0 10.93 0 9.26 0 7.907 1.31 7.907 2.925c0 1.615 1.353 2.925 3.023 2.925ZM14.961 6.75H6.434c-.482.012-.864.399-.852.865v5.193c-.068 2.8 2.222 5.123 5.116 5.192 2.894-.069 5.183-2.392 5.116-5.192V7.615c.011-.466-.37-.853-.853-.865Z"
      />
      <path
        fill="#000"
        d="M11.163 6.75v7.277a.829.829 0 0 1-.53.76.847.847 0 0 1-.321.063H5.99a5.316 5.316 0 0 1-.41-2.043V7.614c-.01-.466.37-.852.851-.864h4.73Z"
        opacity={0.1}
      />
      <path
        fill="#000"
        d="M10.698 6.75v7.726a.775.775 0 0 1-.066.311.857.857 0 0 1-.786.513H6.21a5.75 5.75 0 0 1-.218-.45 5.316 5.316 0 0 1-.41-2.043V7.614c-.01-.466.37-.852.851-.864h4.266Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M10.698 6.75v6.826a.844.844 0 0 1-.852.824H5.828a5.316 5.316 0 0 1-.247-1.593V7.614c-.01-.466.37-.852.851-.864h4.266Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M10.232 6.75v6.826a.844.844 0 0 1-.85.824H5.827a5.316 5.316 0 0 1-.247-1.593V7.614c-.01-.466.37-.852.851-.864h3.8Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M11.163 4.424V5.84c-.08.005-.154.01-.233.01-.079 0-.153-.005-.232-.01a2.631 2.631 0 0 1-.465-.072A2.998 2.998 0 0 1 8.14 4.05a2.443 2.443 0 0 1-.15-.45h2.322c.469.002.849.37.85.824Z"
        opacity={0.1}
      />
      <path
        fill="#000"
        d="M10.698 4.873v.968a2.631 2.631 0 0 1-.465-.072 2.998 2.998 0 0 1-2.093-1.72h1.707c.469.003.849.37.85.824Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M10.698 4.873v.968a2.631 2.631 0 0 1-.465-.072 2.998 2.998 0 0 1-2.093-1.72h1.707c.469.003.849.37.85.824Z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M10.233 4.873v.896a2.998 2.998 0 0 1-2.093-1.72h1.242c.469.003.849.37.85.824Z"
        opacity={0.2}
      />
      <path
        fill="url(#b)"
        d="M.853 4.05H9.38c.47 0 .852.37.852.825v8.25c0 .456-.381.825-.852.825H.853c-.471 0-.853-.37-.853-.825v-8.25c0-.456.382-.825.853-.825Z"
      />
      <path
        fill="#fff"
        d="M7.36 7.19H5.655v4.491H4.57V7.19H2.873v-.871H7.36v.871Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={1.778}
        x2={8.13}
        y1={3.405}
        y2={14.776}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A62C3" />
        <stop offset={0.5} stopColor="#4D55BD" />
        <stop offset={1} stopColor="#3940AB" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default MicrosoftTeams;
