import React, { useMemo, useRef } from 'react';
import Flex from '../Flex';
import cnj from '../utils/cnj';
import Skeleton from '../Skeleton/Skeleton';
import useMedia from '../utils/useMedia';
import useTheme from '../utils/useTheme';
import IconButton from '../Button/IconButton';
import Typography from '../Typography';
import type { FC } from 'react';
import { UploadedFile } from '../AttachmentPicker/AttachmentPicker.component';
import Image from '../Image';
import classes from './AttachmentItem.component.module.scss';
export interface AttachmentItemProps {
  url?: string;
  attachment: UploadedFile;
  className?: string;
  isLoading?: boolean;
  onRemove: (selectedFile: UploadedFile) => void;
  readonly?: boolean;
}

const AttachmentItem: FC<AttachmentItemProps> = (props) => {
  const { onRemove, url, isLoading, attachment, readonly } = props;

  const isImage = attachment?.file?.type?.includes('image');
  const { isDark } = useTheme();
  const { isMoreThanTablet } = useMedia();

  const linkRef = useRef<HTMLAnchorElement>(null);

  const onDownload = () => {
    if (linkRef.current) {
      linkRef.current.click();
    }
  };

  const previewUrl = useMemo(
    () => URL.createObjectURL(attachment?.file),
    [attachment]
  );

  if (isLoading) {
    return (
      <Flex className={classes.root}>
        <div className={isMoreThanTablet ? '' : classes.row}>
          <div className={cnj(classes.content, classes.contentImage)}>
            <Skeleton className={classes.skeleton__1} />
          </div>

          <Skeleton className={classes.skeleton__2} />
        </div>
      </Flex>
    );
  }

  const onRemoveItem = () => {
    onRemove(attachment);
  };

  return (
    <>
      <Flex className={classes.root}>
        <div className={isMoreThanTablet ? classes.fullWidth : classes.row}>
          <div className={cnj(classes.content, classes.contentBorder)}>
            {isImage ? (
              <Image src={previewUrl} alt="preview" className={classes.image} />
            ) : (
              <embed src={previewUrl} className={classes.image} />
            )}

            {isMoreThanTablet && (
              <div className={classes.actions}>
                <IconButton
                  name="download"
                  type="far"
                  colorSchema="transparent-white"
                  onClick={onDownload}
                  className={classes.icon}
                />

                {!readonly && (
                  <IconButton
                    name="trash"
                    type="fal"
                    colorSchema="transparent-white"
                    onClick={onRemoveItem}
                    className={classes.icon}
                  />
                )}
              </div>
            )}
          </div>

          <Typography
            color="gray"
            font="400"
            size={10}
            lineNumber={1}
            isTruncated
            isWordWrap
            className={classes.filename}
          >
            {attachment?.file?.name}
          </Typography>
        </div>

        {!isMoreThanTablet && (
          <div className={classes.row}>
            <IconButton
              name="download"
              type="far"
              colorSchema="transparent-white"
              iconProps={{ color: isDark ? 'gray' : 'coal' }}
              onClick={onDownload}
              className={classes.icon}
            />

            {!readonly && (
              <IconButton
                name="trash"
                type="fal"
                colorSchema="transparent-white"
                onClick={onRemoveItem}
                iconProps={{ color: 'error' }}
                className={classes.icon}
              />
            )}
          </div>
        )}
      </Flex>

      <a
        href={url || previewUrl}
        ref={linkRef}
        download
        className={classes.hidden}
      >
        file link
      </a>
    </>
  );
};

export default AttachmentItem;
