import { useCreateContext } from '@lobox/utils';
import { messageReducer, initialState } from './message.reducer';

const [state, dispatch, provider] = useCreateContext(
  messageReducer,
  initialState
);
export const useMessageState = state;
export const useMessageDispatch = dispatch;
export const MessageProvider = provider;
