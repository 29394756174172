import { SchedulesCalendarProvider } from '@shared/utils/schedulesCalendar.provider';
import ScheduleCreationForm from '@shared/components/Organism/ScheduleCreationForm';
import ScheduleDetailsModal from '@shared/components/Organism/ScheduleDetailsModal';
import { useScheduleState } from '@/shared/contexts/Schedule/schedule.provider';

export function SchedulesProvider({ children }: { children: ReactNode }) {
  const { visibleForm, schedulesEventType } = useScheduleState(
    'scheduleCreationModalData'
  );
  const visibleScheduleEventDetail = schedulesEventType && !visibleForm;
  const visibleScheduleEventForm = schedulesEventType && visibleForm;

  return (
    <>
      <SchedulesCalendarProvider>{children}</SchedulesCalendarProvider>
      {visibleScheduleEventDetail && <ScheduleDetailsModal />}
      {visibleScheduleEventForm && <ScheduleCreationForm />}
    </>
  );
}
