import classes from './useScheduleFormFields.module.scss';

import React, { FC, Fragment } from 'react';
import {
  Endpoints,
  geoApi,
  lookupApi,
  schedulesApi,
  schedulesDb,
  schedulesEventValues,
  searchPerson,
  useDisclosure,
  useTranslation,
} from '@lobox/utils';
import { useFormikContext } from 'formik';
import Avatar from '@lobox/uikit/Avatar';
import AvatarCard from '@lobox/uikit/AvatarCard';
import cnj from '@lobox/uikit/utils/cnj';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import { SCHEDULE_EVENT_DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';
import useGetCurrentTimeZone from '@shared/hooks/api-hook/useGetCurrentTimeZone';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import AdvancedAvatarCard from '@shared/components/molecules/AdvancedAvatarCard';
import AttendeePicker from './AttendeePicker.component';
import { MeetingToolIcon, MeetingToolOption } from './MeetingToolOption';
import {
  FixedRightSideModalDialog,
  ModalHeaderSimple,
} from '@shared/components/molecules/Modal';

const invalidItem = {
  label: 'invalid_time_format',
  value: '',
  noClick: true,
};

const useScheduleFormFields = () => {
  const { t } = useTranslation();
  const { isMoreThanTablet } = useMedia();
  const { authUser } = useGetAppObject();
  const { data: currentTimeZone } = useGetCurrentTimeZone();
  const { isOpen, onToggle } = useDisclosure();
  const { values, setFieldValue } = useFormikContext<any>();
  const isRemote =
    values?.contactType?.value === schedulesDb.meetingModel[0].value;

  const oneOnOnAttendee = Array.isArray(values?.attendees)
    ? values?.attendees?.[0]
    : values?.attendees;
  const meetingTitle = {
    label: t('meeting_title'),
    required: true,
    name: 'title',
    cp: 'input',
    maxLength: 100,
    wrapStyle: 'responsive-margin-top',
  };
  const meetingDate = {
    name: 'startDate',
    cp: 'datePicker',
    wrapStyle: cnj(classes.leftItem, classes.formItem),
    containerProps: {
      className: classes.datePickerRight,
    },
    variant: 'input',
    required: true,
    picker: 'date',
    containerWidth: isMoreThanTablet ? 360 : undefined,
    label: t('date'),
    openToBottom: true,
  };
  const meetingTime = {
    name: 'startTime',
    wrapStyle: cnj(classes.rightItem, classes.formItem),
    label: t('time'),
    required: true,
    doNotUseTranslation: true,
    rightIconProps: {
      name: 'clock',
    },
    maxLength: 5,
    visibleRightIcon: true,
    cp: 'asyncAutoComplete',
    // format: "23:00"
    checkIsValid: (value: string) => /^(\d{0,2}(:\d{0,2})?)?$/.test(value),
    showDropDownWithoutEnteringAnything: true,
    initSearchValue: '',
    apiFunc: ({ text }: { text: string }) => {
      const filtered = schedulesDb.timeOptions?.filter((option) =>
        option.label.includes(text)
      );
      return text
        ? filtered?.length
          ? filtered
          : [invalidItem]
        : schedulesDb.timeOptions;
    },
    onChange: (value, { field }) => {
      if (field?.value?.label?.length === 1 && value?.label?.length === 2) {
        setTimeout(() => {
          setFieldValue('startTime', {
            value: null,
            label: value?.label?.concat(':'),
          });
        });
      }
    },
  };
  const meetingDuration = {
    name: 'duration',
    cp: 'dropdownSelect',
    label: t('meeting_duration'),
    wrapStyle: classes.formItem,
    options: schedulesDb.meetingDuration,
  };

  const location = {
    name: 'location',
    cp: 'cityPicker',
    apiParams: {
      countryCode: authUser?.location?.countryCode,
    },
    wrapStyle: classes.formItem,
    noExplicitEditButton: true,
    classNames: {
      itemWrapper: classes.noBottomMargin,
    },
    rightSideProps: {
      isFromRightSide: true,
      modalComponent: FixedRightSideModalDialog,
      inputLabel: t('city'),
    },
    hideBack: false,
    hideClose: true,
    visibleHeaderBorderBottom: true,
    visibleRequiredHint: false,
    styles: {
      formRoot: classes.locationFormRoot,
      formWrap: classes.locationFormRootFields,
      submitButton: classes.maxWidth,
    },
    primaryAction: {
      label: t('add_location'),
    },
  };

  const room = {
    label: t('room_details'),
    name: 'room',
    cp: 'input',
    wrapStyle: classes.formItem,
    helperText: t('example_address'),
  };

  const timeZone = {
    name: 'timezone',
    cp: 'asyncAutoComplete',
    label: t('time_zone'),
    apiFunc: geoApi.searchTimezone,
    wrapStyle: classes.formItem,
    normalizer: (data = []) =>
      currentTimeZone
        ? [
            {
              label: t('use_cur_t_z'),
              value: currentTimeZone?.id,
              offset: currentTimeZone?.offset,
              code: currentTimeZone?.code,
            },
            ...data,
          ]
        : data,
    visibleRightIcon: true,
    visibleOptionalLabel: false,
    rightIconProps: {
      name: 'search',
    },
  };

  const description = {
    name: 'description',
    cp: 'richTextEditor',
    title: t('description'),
    label: t('description'),
    changeWithDebounce: true,
    maxLength: SCHEDULE_EVENT_DESCRIPTION_MAX_LENGTH,
    visibleCharCounter: true,
    style: classes.description,
  };
  const attachments = {
    name: 'attachmentFiles',
    cp: 'attachmentPicker',
    // wrapStyle: classes.formItem,
    uploadApi: schedulesApi.uploadFile,
    removeApi: schedulesApi.deleteFile,
    getApi: schedulesApi.getFile,
    onChange: (values: any) => {
      setFieldValue('attachmentFiles', values);
    },
  };
  const attendees = {
    name: 'attendees',
    cp: (props) => (
      <AttendeePicker
        leftLabel={t('attendees')}
        buttonLabel={t('edit_attendees')}
        accessLabel={t('modify_meeting')}
        isOpen={isOpen}
        onToggle={onToggle}
        creator={authUser}
        permissionValue={schedulesDb.permissions.MODIFY_MEETING}
        {...props}
      />
    ),
  };
  const attendee = {
    name: 'attendees',
    label: t('attendee'),
    cp: 'asyncAutoComplete',
    apiFunc: searchPerson,
    maxLength: 100,
    helperText: t('company_helper'),
    normalizer: (data) =>
      data?.content
        ?.map((item) => ({
          value: item.id,
          label: item.fullName,
          image: item.croppedImageUrl,
        }))
        ?.filter((item) => item?.value !== authUser?.id),
    visibleRightIcon: true,
    leftIcon: oneOnOnAttendee ? (
      <Avatar imgSrc={oneOnOnAttendee.image} size="sm" />
    ) : undefined,
    renderItem: ({ item }: any) => (
      <AvatarCard
        data={{
          title: item.label,
          image: item.image,
        }}
        avatarProps={{ size: 'sm' }}
      />
    ),
  };

  const meetingModel = {
    name: 'contactType',
    cp: 'dropdownSelect',
    label: t('meeting_model'),
    required: true,
    options: schedulesDb.meetingModel,
  };

  const meetingTool = {
    name: 'externalConferenceProviderType',
    cp: 'dropdownSelect',
    label: t('meeting_tool'),
    required: true,
    options: schedulesDb.meetingTools,
    wrapStyle: classes.formItem,
    renderItem: ({ item, isSelected }) => (
      <MeetingToolOption type={item?.value} isSelected={isSelected} />
    ),
    styles: {
      options: classes.meetingToolOptions,
    },
    leftIcon: (
      <MeetingToolIcon type={values?.externalConferenceProviderType?.value} />
    ),
    textInputProps: {
      labelStyle: classes.meetingToolLabel,
      leftIconClassname: classes.leftIconMeetingTool,
    },
    inputStyle: classes.noPaddingLeft,
  };
  const meetingRemind = {
    name: 'remind',
    cp: 'dropdownSelect',
    label: t('meeting_reminder'),
    wrapStyle: classes.formItem,
    options: schedulesDb.meetingReminder,
  };

  const reminderTitle = {
    ...meetingTitle,
    label: t('reminder_title'),
    wrapStyle: 'responsive-margin-top',
  };

  const reminderDate = {
    ...meetingDate,
    name: 'startDate',
    wrapStyle: classes.formItem,
    label: t('date'),
    containerProps: {
      className: '',
    },
  };
  const reminderTime = {
    ...meetingTime,
    name: 'startTime',
    label: t('time'),
    required: undefined,
    disabled: values?.allDay,
    wrapStyle: cnj(classes.formItem, classes.time),
  };
  const allDay = {
    name: 'allDay',
    cp: 'switch',
    label: t('all_day'),
    wrapStyle: cnj(classes.formItem, classes.allDay),
    visibleOptionalLabel: false,
    labelProps: {
      font: '400',
      className: classes.allDayLabel,
    },
  };

  const reminderRepeat = {
    name: 'repeatType',
    cp: 'dropdownSelect',
    label: t('repeat'),
    wrapStyle: classes.formItem,
    options: [],
  };

  const creator = {
    name: 'user',
    cp: () => (
      <AdvancedAvatarCard
        {...{
          firstText: authUser.fullName,
          secondText: authUser.usernameAtSign,
          thirdText: t('creator'),
        }}
        avatarProps={{ size: 'slg', imgSrc: authUser.croppedImageUrl }}
      />
    ),
  };
  const taskTitle = {
    label: t('task_title'),
    required: true,
    name: 'title',
    cp: 'input',
    maxLength: 100,
    wrapStyle: 'responsive-margin-top',
  };

  const taskStartDate = {
    ...meetingDate,
    required: false,
    wrapStyle: cnj(classes.leftItem, classes.formItem),
    label: t('start_date'),
    name: 'startDate',
  };
  const taskStartTime = {
    ...meetingTime,
    wrapStyle: cnj(classes.rightItem, classes.formItem),
    required: false,
    label: t('start_time'),
    name: 'startTime',
  };
  const taskEndDate = {
    ...meetingDate,
    minDate: new Date(values?.startDate),
    wrapStyle: cnj(classes.leftItem, classes.formItem),
    required: false,
    label: t('end_date'),
    name: 'endDate',
    containerProps: {
      className: classes.datePickerRight,
    },
  };

  const taskEndTime = {
    ...meetingTime,
    wrapStyle: cnj(classes.rightItem, classes.formItem),
    required: false,
    label: t('end_time'),
    name: 'endTime',
    containerProps: {
      className: '',
    },
    apiFunc: ({ text }: { text: string }) =>
      text
        ? schedulesDb.timeOptionsEnd?.filter((option) =>
            option.label.includes(text)
          )
        : schedulesDb.timeOptionsEnd,
    onChange: (value, { field }) => {
      if (field?.value?.label?.length === 1 && value?.label?.length === 2) {
        setTimeout(() => {
          setFieldValue('startTime', {
            value: null,
            label: value?.label?.concat(':'),
          });
        });
      }
    },
  };
  const hashtags = {
    wrapStyle: classes.formItem,
    name: 'hashtags',
    cp: 'hashtagPicker',
  };

  const category = {
    name: 'category',
    label: t('category'),
    cp: 'asyncAutoComplete',
    maxLength: 100,
    url: Endpoints.App.Common.getIndustry,
    normalizer: (data: any) =>
      data.map(({ id: value, title: label }: any) => ({
        label,
        value,
      })),
    wrapStyle: classes.formItem,
    visibleRightIcon: true,
  };

  const taskCollaborators = {
    required: true,
    name: 'collaborators',
    accessLabel: 'modify_task',
    cp: (props) => (
      <AttendeePicker
        leftLabel={t('collaborators')}
        buttonLabel={t('edit_collaborators')}
        isOpen={isOpen}
        onToggle={onToggle}
        creator={authUser}
        permissionValue={schedulesDb.permissions.MODIFY_TASK}
        {...props}
      />
    ),
  };
  const divider = {
    cp: () => <Divider className={classes.divider} />,
    name: 'divider',
  };

  const meeting = () =>
    [
      creator,
      meetingTitle,
      meetingDate,
      meetingTime,
      meetingDuration,
      timeZone,
      meetingRemind,
      divider,
      description,
      { ...divider, name: 'divider2' },
      meetingModel,
      ...(isRemote ? [meetingTool] : [location, room]),

      { ...divider, name: 'divider3' },
      attendees,
      { ...divider, name: 'divider4' },
      attachments,
    ].filter(Boolean);

  const reminder = () => [
    reminderTitle,
    reminderDate,
    reminderTime,
    allDay,
    // reminderRepeat,
  ];

  const task = () => [
    creator,
    taskTitle,
    taskStartDate,
    taskStartTime,
    taskEndDate,
    taskEndTime,
    // reminderRepeat,
    divider,
    description,
    { ...divider, name: 'divider2' },
    taskCollaborators,
    // hashtags,
    // category,
    { ...divider, name: 'divider3' },
    attachments,
  ];

  return {
    [schedulesEventValues.MEETING]: meeting,
    [schedulesEventValues.REMINDER]: reminder,
    [schedulesEventValues.TASK]: task,
  };
};

export default useScheduleFormFields;
