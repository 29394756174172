import classes from './index.module.scss';

import React, { useLayoutEffect, useRef, useState } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Skeleton from '@lobox/uikit/Skeleton';
import partial from 'lodash/partial';
import NavLink from './NavLink';

interface StyleProps {
  content?: string;
  linksRoot?: string;
  tabsWrap?: string;
  linksWrap?: string;
  tabsRoot?: string;
}

interface TabsProps {
  className?: string;
  tabs: Array<{
    title: string;
    path: string;
    width?: string | number;
    subText?: string | number;
    titleClass?: string;
    center?: boolean;
    content?: () => React.ReactElement;
    icon?: React.ReactNode;
  }>;
  children?: React.ReactNode;
  rightAction?: React.ReactNode;
  leftAction?: React.ReactNode;
  isLoading?: boolean;
  activePath?: string;
  styles?: Partial<StyleProps>;
  visibleTabs?: boolean;
  isFullWidth?: boolean;
  onChangeTab?: (path: string) => void;
}

const Tabs: React.FC<TabsProps> = ({
  styles,
  tabs,
  leftAction,
  rightAction,
  isLoading,
  activePath,
  onChangeTab,
  visibleTabs = true,
  isFullWidth = true,
}: TabsProps) => {
  const tabsList = tabs.filter(Boolean);
  const firstUpdate = useRef(true);
  const [active, setActive] = useState(activePath || tabs[0]?.path);
  const activeContent = tabs.find((item) => item.path === active)?.content;

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    onChangeTab?.(active);
  }, [active]);

  return (
    <Flex className={cnj(classes.tabsRoot, styles?.tabsRoot)}>
      <Flex className={cnj(classes.tabsWrap, styles?.tabsWrap)}>
        <Flex className={cnj(classes.linksRoot, styles?.linksRoot)}>
          <Flex className={cnj(classes.linksWrap, styles?.linksWrap)}>
            {isLoading ? (
              <>
                {[1, 2, 3, 4].map((i) => (
                  <Skeleton key={i} className={classes.skeleton} />
                ))}
              </>
            ) : (
              <>
                {leftAction}
                {visibleTabs &&
                  tabsList.map(
                    (
                      { title, subText, titleClass, path, width, icon, center },
                      index
                    ) => (
                      <NavLink
                        key={path}
                        {...{
                          isFullWidth: isFullWidth && tabs?.length > 1,
                          onClick: partial(setActive, path),
                          isActive: active === path,
                          center,
                          subText,
                          titleClass,
                          title,
                          width,
                          icon,
                          isFirstLink: index === 0,
                        }}
                      />
                    )
                  )}
                {rightAction}
              </>
            )}
          </Flex>
        </Flex>
        {activeContent && (
          <Flex className={cnj(classes.content, styles?.content)}>
            {activeContent()}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Tabs;
