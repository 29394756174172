'use client';

import React from 'react';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import ThemeProvider from '@lobox/uikit/ThemeProvider';
import { GlobalProvider } from '@lobox/utils/contexts/Global/global.provider';
import { AuthProvider } from '@lobox/utils/contexts/Auth/auth.provider';
import { LanguageProvider } from '@lobox/utils/contexts/Language';
import QueryKeys from '@lobox/utils/constants/enums/queryKeys';
import Profiler from '@lobox/uikit/Optimization/Profiler';
import { SchedulesProvider } from '@shared/providers/SchedulesProvider';
import ConfirmProvider from '@shared/uikit/Confirmation/confirm.provider';
import dynamic from 'next/dynamic';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PostCreator from '@shared/components/Organism/PostCreator';
import { BackToLastPage } from './BackToLastPageProvider';

const Observer = dynamic(() => import('./Observers'), { ssr: false });

interface Props extends React.PropsWithChildren {
  authUser: any;
  businessPage: any;
  isDark: boolean;
  lng: string;
}

function Providers({ authUser, businessPage, isDark, lng, children }: Props) {
  const [queryClient] = React.useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: 0,
          staleTime: 5 * 1000,
        },
      },
    })
  );

  if (authUser) {
    queryClient.setQueryData([QueryKeys.authUser], authUser);
  }
  if (businessPage) {
    queryClient.setQueryData([QueryKeys.businessPage], businessPage);
  }

  return (
    <>
      <Profiler id="app-root">
        <LanguageProvider lng={lng}>
          <GlobalProvider initialValue={{ isBusinessApp }}>
            <AuthProvider
              initialValue={{ isLoggedIn: !!authUser, isValidRoute: true }}
            >
              <QueryClientProvider client={queryClient}>
                <ReactQueryStreamedHydration>
                  <ThemeProvider appreance="auto" isDark={isDark}>
                    <ConfirmProvider>
                      <SchedulesProvider>{children}</SchedulesProvider>
                      <PostCreator />
                      <BackToLastPage />
                    </ConfirmProvider>
                  </ThemeProvider>
                  <Observer />
                </ReactQueryStreamedHydration>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </AuthProvider>
          </GlobalProvider>
        </LanguageProvider>
      </Profiler>
    </>
  );
}

export default Providers;
