import classes from './ScheduleHolidayDetails.module.scss';

import React from 'react';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import { formatDate, useTranslation } from '@lobox/utils';
import HolidaySvg from '@shared/svg/HolidaySvg';

const ScheduleHolidayDetails: React.FC = () => {
  const { t } = useTranslation();
  const { event } = useSchedulesEvent();

  const date = event.startTime ? formatDate(event.startTime) : undefined;

  return (
    <Flex className={classes.scheduleMeetingDetailsRoot}>
      <Flex>
        <HolidaySvg className={classes.svg} />
        <Divider className={classes.divider} />
      </Flex>
      <Typography font="700" size={20} height={23}>
        {event?.name}
      </Typography>
      <Flex className={classes.itemWrapper}>
        <Icon size={14} name="calendar-rectangle" type="far" />
        <Typography ml={8}>{date}</Typography>
      </Flex>
      <Flex className={classes.itemWrapper}>
        <Icon size={14} name="calender-star-light" type="far" />
        <Typography ml={8}>
          {`${t('holidays_in')} ${event?.location}`}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default ScheduleHolidayDetails;
