import classes from './Actions.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Button from '@lobox/uikit/Button';
import Flex from '@lobox/uikit/Flex';
import { useTranslation } from '@lobox/utils';
import {
  useScheduleDispatch,
  useScheduleState,
} from '@/shared/contexts/Schedule/schedule.provider';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import useDeleteScheduleEvent from '@shared/hooks/useDeleteScheduleEvent';
import type { ISchedulesEvent } from '@lobox/utils';

export interface Props {
  className?: string;
  event: ISchedulesEvent;
}

const Actions: React.FC<Props> = (props) => {
  const { event, className } = props;
  const { t } = useTranslation();
  const scheduleDispatch = useScheduleDispatch();

  const { schedulesEventType, eventId, hasEditAccess } = useSchedulesEvent();

  const scheduleCreationModalDataState = useScheduleState(
    'scheduleCreationModalData'
  );
  const { deleteHandler: deleteScheduleEvent, onDeletionSuccessHandler } =
    useDeleteScheduleEvent(schedulesEventType, eventId);

  const editHandler = () => {
    scheduleDispatch({
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
      payload: {
        ...scheduleCreationModalDataState,
        visibleForm: true,
        schedulesEventType,
        eventId,
      },
    });
  };

  const deleteHandler = () => {
    deleteScheduleEvent({
      eventId: event.id,
      eventTitle: event.title,
      schedulesEventType: event.schedulesEventType,
      onSuccess: onDeletionSuccessHandler,
    });
  };

  return (
    hasEditAccess && (
      <Flex className={cnj(classes.textRoot, className)}>
        <Button
          onClick={deleteHandler}
          fullWidth
          schema="error-semi-transparent"
          label={t('remove')}
          leftIcon="trash"
          leftType="far"
        />
        <Button
          onClick={editHandler}
          fullWidth
          leftIcon="pen"
          label={t('edit')}
          leftType="far"
          schema="gray"
        />
      </Flex>
    )
  );
};

export default Actions;
