import classes from './ScheduleCreationForm.content.module.scss';

import React, { useEffect } from 'react';
import Button from '@lobox/uikit/Button';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import Flex from '@lobox/uikit/Flex';
import InfoCard from '@lobox/uikit/InfoCard';
import Spinner from '@lobox/uikit/Spinner';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import Typography from '@lobox/uikit/Typography';
import useMedia from '@lobox/uikit/utils/useMedia';
import useTheme from '@lobox/uikit/utils/useTheme';
import {
  ModalBody,
  ModalFooter,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import { useFormikContext } from 'formik';
import { motion } from 'framer-motion';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import {
  dayjs,
  schedulesDb,
  schedulesEventValues,
  useTranslation,
} from '@lobox/utils';
import Tabs from '@shared/components/containers/Tabs';
import { useScheduleDispatch } from '@/shared/contexts/Schedule/schedule.provider';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import { useSchedulesCalendarDispatch } from '@shared/utils/schedulesCalendar.provider';
import { Time } from '@shared/utils/Time';
import useScheduleFormFields from './partials/useScheduleFormFields';

const ScheduleCreationFormContent: React.FC = () => {
  const { t } = useTranslation();
  const {
    schedulesEventType,
    isCreationMode,
    queryResult,
    backHandler,
    closeHandler,
    creationInitialData,
  } = useSchedulesEvent();
  const formFields = useScheduleFormFields();
  const scheduleDispatch = useScheduleDispatch();
  const formContext = useFormikContext();
  const { isDark } = useTheme();
  const { isTabletAndLess } = useMedia();
  const calendarDispatch = useSchedulesCalendarDispatch();

  const groups = formFields[schedulesEventType]?.() || [];

  const startDate = formContext?.values?.startDate;
  const startTime = formContext?.values?.startTime;
  const endDate = formContext?.values?.endDate;
  const endTime = formContext?.values?.endTime;

  const tabs = [
    {
      path: schedulesEventValues.MEETING,
      title: t('meeting'),
      headerTitle: isCreationMode ? t('create_meeting') : t('edit_meeting'),
    },
    {
      path: schedulesEventValues.REMINDER,
      title: t('reminder'),
      headerTitle: isCreationMode ? t('create_reminder') : t('edit_reminder'),
    },
    {
      path: schedulesEventValues.TASK,
      title: t('task'),
      headerTitle: isCreationMode ? t('create_task') : t('edit_task'),
    },
  ];
  const title = tabs?.find((i) => i.path === schedulesEventType)?.headerTitle;

  const setActiveTab = (tab: string) => {
    scheduleDispatch({
      type: 'SET_SCHEDULE_CREATION_MODAL_DATA',
      payload: {
        visibleForm: true,
        schedulesEventType: tab,
        creationInitialData,
      },
    });
  };

  const isValidTime = (text: string) =>
    schedulesDb.timeOptions?.find((option) => option.label.includes(text));

  useEffect(() => {
    if (
      !startDate ||
      !startTime ||
      !isValidTime(startTime?.value) ||
      !isCreationMode
    )
      return;
    const start = Time.convertBackFormatToFront(
      Time.getBackendDateTimeFromDateAndTime(startDate, startTime?.value)
    );
    const end =
      endDate &&
      endTime?.value &&
      isValidTime(endTime?.value) &&
      Time.convertBackFormatToFront(
        Time.getBackendDateTimeFromDateAndTime(endDate, endTime?.value)
      );

    if (end && !dayjs(end).isAfter(start)) {
      return;
    }
    calendarDispatch({
      type: 'SET_TEMPORARY_EVENT',
      payload: {
        id: `${Number.MAX_SAFE_INTEGER}`,
        title: t('no_title'),
        type: 'TEMPORARY',
        startTime: start,
        endTime: end,
      },
    });
  }, [startDate, startTime, endDate, endTime]);

  const isEventInThePast =
    startDate &&
    startTime?.value &&
    isValidTime(startTime?.value) &&
    Time.isBeforeNow(
      Time.convertBackFormatToFront(
        Time.getBackendDateTimeFromDateAndTime(startDate, startTime?.value)
      )
    );

  const warningBoxTitle = {
    [schedulesEventValues.MEETING]: t('this_meeting_set_past'),
    [schedulesEventValues.REMINDER]: t('this_reminder_set_past'),
    [schedulesEventValues.TASK]: t('this_task_set_past'),
  };
  return (
    <FixedRightSideModalDialog
      onBack={backHandler}
      onClose={closeHandler}
      showConfirm={formContext?.dirty}
    >
      <ModalHeaderSimple
        closeButtonProps={{
          className: !isCreationMode ? classes.hideBack : classes.showBack,
        }}
        backButtonProps={{
          onClick: isCreationMode ? closeHandler : backHandler,
          className: isCreationMode ? classes.hideBack : classes.showBack,
        }}
        hideBack={false}
        visibleHeaderDivider
        title={title}
        className={classes.largeModalHeader}
      />
      {isCreationMode && (
        <Tabs
          onChangeTab={setActiveTab}
          activePath={schedulesEventType}
          tabs={tabs}
        />
      )}
      {queryResult.isLoading ? (
        <Spinner />
      ) : (
        <>
          <ModalBody className={classes.modalBody}>
            <motion.div
              id="modalMotion"
              initial="visible"
              animate="hidden"
              variants={{
                visible: { originX: 0, opacity: 0, scale: 0.95 },
                hidden: { originX: 0.5, opacity: 1, scale: 1 },
              }}
              transition={{ duration: 0.2 }}
            >
              {isEventInThePast && (
                <InfoCard
                  classNames={{
                    wrapper: classes.warningBox,
                  }}
                >
                  {warningBoxTitle?.[schedulesEventType]}
                </InfoCard>
              )}
              <DynamicFormBuilder groups={groups} />
            </motion.div>
          </ModalBody>

          <ModalFooter className={classes.footer}>
            <Button
              onClick={closeHandler}
              fullWidth
              schema={isDark ? 'ghost-black' : 'secondary-light'}
              label={t('cancel')}
            />
            <Flex className={classes.divider} />
            <SubmitButton
              fullWidth
              active={!isCreationMode}
              label={isCreationMode ? t('create') : t('update')}
            />
          </ModalFooter>
        </>
      )}
    </FixedRightSideModalDialog>
  );
};

export default ScheduleCreationFormContent;
