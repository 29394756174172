import React from 'react';
import { useScheduleState } from '@/shared/contexts/Schedule/schedule.provider';
import { schedulesEventValues, useTranslation } from '@lobox/utils';
import ShareEvent from './partials/ShareEvent';
import Actions from './partials/Actions';
import ScheduleDetailsModalFooterMeeting from './ScheduleEventDetailsModal.footer.meeting';
import type { ISchedulesEvent } from '@lobox/utils';

const ScheduleDetailsModalFooterFooter: React.FC<{
  event: ISchedulesEvent;
}> = ({ event }) => {
  const { t } = useTranslation();
  const { schedulesEventType } = useScheduleState('scheduleCreationModalData');

  if (schedulesEventType === schedulesEventValues.MEETING) {
    return <ScheduleDetailsModalFooterMeeting event={event} />;
  }
  if (schedulesEventType === schedulesEventValues.TASK) {
    return <ShareEvent event={event} label={t('share_task')} />;
  }

  if (schedulesEventType === schedulesEventValues.REMINDER) {
    return <Actions event={event} />;
  }

  return null;
};

export default ScheduleDetailsModalFooterFooter;
