import React from 'react';
import Form from '@lobox/uikit/Form';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import useScheduleForm from './partials/useScheduleForm';
import ScheduleCreationFormContent from './ScheduleCreationForm.content';

const ScheduleCreationForm: React.FC = () => {
  const {
    initialValues,
    apiFunc,
    transform,
    onSuccessHandler,
    validationSchema,
  } = useScheduleForm();
  const { schedulesEventType } = useSchedulesEvent();

  return (
    <Form
      key={schedulesEventType}
      initialValues={initialValues}
      apiFunc={apiFunc}
      onSuccess={onSuccessHandler}
      transform={transform}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {() => <ScheduleCreationFormContent />}
    </Form>
  );
};

export default ScheduleCreationForm;
