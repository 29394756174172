import {
  dayjs,
  formValidator,
  routeNames,
  schedulesApi,
  schedulesDb,
  schedulesEventValues,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import { useHistory } from '@lobox/utils';
import useToast from '@lobox/uikit/Toast/useToast';
import { Time } from '@shared/utils/Time';
import useGetCurrentTimeZone from '@shared/hooks/api-hook/useGetCurrentTimeZone';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import getSchedulesSectionsQueryKey from '@shared/utils/getSchedulesSectionsQueryKey';
import { useSetEvents } from '@/shared/hooks/useSetEvents';

const useScheduleForm = () => {
  const {
    schedulesEventType,
    event,
    isCreationMode,
    backHandler,
    closeHandler,
    queryResult,
    creationInitialData,
    isProfileTab,
  } = useSchedulesEvent();
  const { t } = useTranslation();
  const toast = useToast();
  const history = useHistory();
  const { data: currentTimeZone = {} as any } = useGetCurrentTimeZone();
  const { pastQueryKey, upComingQueryKey } =
    getSchedulesSectionsQueryKey(schedulesEventType);
  const { refetch: reftechPast } = useUpdateQueryData(pastQueryKey);
  const { refetch: refetchUpcoming } = useUpdateQueryData(upComingQueryKey);
  const { fetchEvents } = useSetEvents();
  const offsetDifference = event?.timezone?.offset
    ? Number(event?.timezone?.offset) - Number(currentTimeZone?.offset)
    : 0;
  const defaultTimeZone = {
    ...currentTimeZone,
    label: t('use_cur_t_z'),
    value: currentTimeZone?.id,
  };
  const initialValues = {
    [schedulesEventValues.MEETING]: {
      timezone: defaultTimeZone,
      externalConferenceProviderType: schedulesDb.meetingTools[0],
      contactType: schedulesDb.meetingModel[0],
      ...creationInitialData,
    },
    [schedulesEventValues.REMINDER]: { ...creationInitialData },
    [schedulesEventValues.TASK]: {
      ...creationInitialData,
    },
  };
  const editInitialValues = {
    ...event,
    startTime: Time.getFormTime(
      Time.getTimeWithOffset(event?.startTime, offsetDifference)
    ),
    startDate: Time.getFormDate(
      Time.getTimeWithOffset(event?.startTime, offsetDifference)
    ),
    endTime: event?.endTime?.isValid()
      ? Time.getFormTime(
          Time.getTimeWithOffset(event?.endTime, offsetDifference)
        )
      : undefined,
    endDate: event?.endTime?.isValid()
      ? Time.getFormDate(
          Time.getTimeWithOffset(event?.endTime, offsetDifference)
        )
      : undefined,
  };

  const apiFunc = {
    [schedulesEventValues.MEETING]: isCreationMode
      ? schedulesApi.createMeeting
      : schedulesApi.updateMeeting,
    [schedulesEventValues.REMINDER]: isCreationMode
      ? schedulesApi.createReminder
      : schedulesApi.updateReminder,
    [schedulesEventValues.TASK]: isCreationMode
      ? schedulesApi.createTask
      : schedulesApi.updateTask,
  };

  const transform = {
    [schedulesEventValues.MEETING]: ({
      duration,
      remind,
      externalConferenceProviderType,
      startTime,
      timezone,
      startDate,
      attendees,
      attachmentFiles,
      contactType,
      location,
      ...rest
    }) => ({
      ...rest,
      // attachmentFileIds,
      attendees: (Array.isArray(attendees)
        ? attendees?.map((item) => ({
            userId: item.id,
            permissions: item?.permissions || [],
          }))
        : [
            {
              userId: attendees?.value,
              permissions: attendees?.permissions || [],
            },
          ]
      )?.filter((attendee) => !!attendee?.userId),
      duration: duration?.value,
      externalConferenceProviderType: externalConferenceProviderType?.value,
      contactType: contactType?.value,
      remind: remind?.value,
      start: Time.getBackendDateTimeFromDateAndTime(
        startDate,
        startTime?.value,
        event?.timezone?.offset
      ),
      timezoneCode: timezone?.code,
      timezoneId: timezone?.value,
      timezoneLabel: timezone?.label,
      timezoneOffset: timezone?.offset,
      attachmentFiles: attachmentFiles?.map((item) => ({
        id: item?.id,
        name: item?.file?.name,
        size: item?.file?.size,
        type: item?.file?.type,
      })),
      location: location?.[0]?.location,
      meetingType: undefined,
    }),
    [schedulesEventValues.REMINDER]: ({
      repeatType,
      startTime,
      startDate,
      allDay,
      ...rest
    }) => ({
      ...rest,
      repeatType: repeatType?.value,
      allDay: allDay || false,
      datetime: Time.getBackendDateTimeFromDateAndTime(
        startDate,
        startTime?.value
      ),
    }),
    [schedulesEventValues.TASK]: ({
      startTime,
      endTime,
      // category,
      collaborators,
      startDate,
      endDate,
      attachmentFiles,
      ...rest
    }) => ({
      ...rest,
      // attachmentFileIds,
      collaborators: collaborators?.map((item) => ({
        userId: item.id,
        permissions: item?.permissions || [],
      })),
      // categoryId: category?.value,
      // categoryName: category?.label,
      start: Time.getBackendDateTimeFromDateAndTime(
        startDate,
        startTime?.value
      ),
      end: Time.getBackendDateTimeFromDateAndTime(endDate, endTime?.value),
      attachmentFiles: attachmentFiles?.map((item) => ({
        id: item?.id,
        name: item?.file?.name,
        size: item?.file?.size,
        type: item?.file?.type,
      })),
    }),
  };
  const messages = {
    [schedulesEventValues.MEETING]: isCreationMode
      ? t('meeting_add_success')
      : t('meeting_updated_success'),
    [schedulesEventValues.REMINDER]: isCreationMode
      ? t('reminder_add_success')
      : t('reminder_updated_success'),
    [schedulesEventValues.TASK]: isCreationMode
      ? t('task_add_success')
      : t('task_updated_success'),
  };
  const routes = {
    [schedulesEventValues.MEETING]: routeNames.schedulesMeetings,
    [schedulesEventValues.REMINDER]: routeNames.schedulesReminders,
    [schedulesEventValues.TASK]: routeNames.schedulesTasks,
  };

  const validationSchemas = {
    [schedulesEventValues.MEETING]: formValidator.object().shape({
      startTime: formValidator
        .object()
        .test('format', 'invalid_time_format', (val: any) =>
          schedulesDb.timeOptions.some((item) => item?.label === val?.label)
        ),
    }),
    [schedulesEventValues.REMINDER]: formValidator.object().shape({
      startTime: formValidator
        .object()
        .test('format', 'invalid_time_format', (val: any) =>
          schedulesDb.timeOptions.some((item) => item?.label === val?.label)
        )
        .when(['allDay'], (allDay, schema) =>
          allDay ? schema.nullable() : schema.required()
        ),
    }),
    [schedulesEventValues.TASK]: formValidator
      .object()
      .shape({
        startTime: formValidator
          .object()
          .test('format', 'invalid_time_format', (val: any) =>
            schedulesDb.timeOptions.some((item) => item?.label === val?.label)
          ),
        endTime: formValidator
          .object()
          .test('format', 'invalid_time_format', (val: any) =>
            schedulesDb.timeOptionsEnd.some(
              (item) => item?.label === val?.label
            )
          ),
      })
      .test('endDate is after startDate', '', (values, obj) => {
        const { startDate, startTime, endDate, endTime } =
          values || ({} as any);

        if (![startDate, startTime, endDate, endTime].every(Boolean))
          return true;

        const start = Time.convertBackFormatToFront(
          Time.getBackendDateTimeFromDateAndTime(startDate, startTime?.value)
        );
        const end = Time.convertBackFormatToFront(
          Time.getBackendDateTimeFromDateAndTime(endDate, endTime?.value)
        );

        if (dayjs(end).isAfter(start) || dayjs(end).isSame(start, 'second'))
          return true;
        return obj.createError({
          path: 'endTime',
          message: t('end_after_start'),
        });
      }),
  };

  const onSuccessHandler = () => {
    reftechPast();
    refetchUpcoming();
    toast({
      type: 'success',
      icon: 'check-circle',
      message: messages[schedulesEventType],
    });
    if (isCreationMode) {
      closeHandler();
    } else {
      queryResult.refetch();
      backHandler();
    }
    fetchEvents();
  };

  return {
    initialValues: isCreationMode
      ? initialValues[schedulesEventType]
      : editInitialValues,
    apiFunc: apiFunc[schedulesEventType],
    transform: transform[schedulesEventType],
    validationSchema: validationSchemas[schedulesEventType],
    onSuccessHandler,
  };
};

export default useScheduleForm;
