import classes from './ScheduleMeetingDetails.module.scss';

import React from 'react';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import { formatDate, schedulesDb, useTranslation } from '@lobox/utils';
import { Time } from '@shared/utils/Time';
import useGetCurrentTimeZone from '@shared/hooks/api-hook/useGetCurrentTimeZone';
import Text from './Text';
import MeetingTool from './MeetingTool';
import DividerHorizontal from './DividerHorizontal';
import Actions from './Actions';
import Attachments from './Attachments';
import AdvancedAvatarCard from '@shared/components/molecules/AdvancedAvatarCard';
import OnSiteAddress from './OnSiteAddress';
import Collaborators from './Collaborators';

const ScheduleMeetingDetails: React.FC = () => {
  const { t } = useTranslation();
  const { event, hasEditAccess } = useSchedulesEvent();

  const { data: userTimezone } = useGetCurrentTimeZone();
  const offsetDifference = event?.timezone?.offset
    ? Number(event?.timezone?.offset) - Number(userTimezone?.offset)
    : 0;

  const isRemote =
    event?.contactType?.value === schedulesDb.meetingModel[0]?.value;

  const hasAttachments = event?.attachmentFiles?.[0]?.id;
  const attendees = !!event ? [event?.creator, ...event?.attendees] : [];

  return (
    <Flex className={classes.scheduleMeetingDetailsRoot}>
      <AdvancedAvatarCard
        firstText={event.creator?.fullName}
        secondText={event.creator?.usernameAtSign}
        thirdText={t('creator')}
        avatarProps={{ size: 'slg', imgSrc: event.creator?.croppedImageUrl }}
      />
      <DividerHorizontal />
      <Text
        className={classes.zeroMarginTop}
        label={t('meeting_title')}
        value={event.title}
      />
      <Flex flexDir="row">
        <Text
          label={t('date')}
          value={formatDate(
            Time.getTimeWithOffset(event?.startTime, offsetDifference)
          )}
        />
        <DividerVertical className={classes.divider} />
        <Text
          label={t('time')}
          value={Time.getFormTime(event?.startTime, offsetDifference)?.label}
        />
      </Flex>
      <Text
        label={t('meeting_duration')}
        value={
          schedulesDb.meetingDuration.find(
            (item) => item?.value === event.duration?.value
          )?.shortLabel
        }
      />
      <Text label={t('time_zone')} value={event.timezone?.label} />
      {event?.remind && (
        <Text label={t('meeting_reminder')} value={event.remind.label} />
      )}
      <DividerHorizontal />
      <Text
        label={t('description')}
        value={event.description}
        isRichText
        className={classes.zeroMarginTop}
      />
      <DividerHorizontal />
      <Text
        className={classes.zeroMarginTop}
        label={t('meeting_model')}
        value={event.contactType?.label}
      />
      {isRemote && <MeetingTool value={event.externalConferenceProviderType} />}
      {!isRemote && <OnSiteAddress value={event?.location} />}
      {!isRemote && <Text label={t('room_details')} value={event.room} />}
      <>
        <DividerHorizontal />
        <Collaborators value={attendees} labelKey="attendees" />
      </>

      {hasAttachments && (
        <>
          <DividerHorizontal />
          <Attachments
            value={event?.attachmentFiles}
            className={classes.zeroMarginTop}
          />
        </>
      )}
      {hasEditAccess && <DividerHorizontal />}
      <Actions event={event} className={classes.actionsWrapper} />
    </Flex>
  );
};

export default ScheduleMeetingDetails;
