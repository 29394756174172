import classes from './index.module.scss';

import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import type { AvatarProps } from '@lobox/uikit/Avatar';
import type { TextProps } from '@lobox/uikit/Typography';

interface AdvancedAvatarCardProps {
  firstText: string;
  secondText: string;
  thirdText: string;
  firstTextProps?: TextProps;
  secondTextProps?: TextProps;
  thirdTextProps?: TextProps;
  avatarProps: AvatarProps;
  leftWrapperClassName?: string;
  className?: string;
}

const AdvancedAvatarCard: React.FC<AdvancedAvatarCardProps> = ({
  leftWrapperClassName,
  firstText,
  secondText,
  thirdText,
  firstTextProps,
  secondTextProps,
  thirdTextProps,
  avatarProps,
  className,
}) => {
  const commonProps = {
    isWordWrap: true,
    isTruncated: true,
    lineNumber: 1,
    className: classes.wordBreak,
  };

  return (
    <Flex className={cnj(classes.wrapper, className)}>
      <Avatar {...avatarProps} />
      <Flex className={cnj(classes.leftWrapper, leftWrapperClassName)}>
        <Typography
          {...{
            size: 20,
            height: 20,
            color: 'thirdText',
            font: '700',
            ...commonProps,
            ...firstTextProps,
          }}
        >
          {firstText}
        </Typography>
        <Typography
          {...{
            height: 21,
            color: 'border',
            ...commonProps,
            ...secondTextProps,
          }}
        >
          {secondText}
        </Typography>
        <Typography
          {...{
            color: 'primaryText',
            size: 14,
            height: 21,
            ...commonProps,
            ...thirdTextProps,
          }}
        >
          {thirdText}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default AdvancedAvatarCard;
