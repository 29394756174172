import classes from './Text.module.scss';

import React, { useCallback, useMemo } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import RichTextEditor from '@lobox/uikit/RichTextEditor';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import { removeBreaksAndSpaces, useTranslation } from '@lobox/utils';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';

export interface TextProps {
  className?: string;
  label: string;
  value: string;
  isRichText?: boolean;
}

const Text: React.FC<TextProps> = ({ label, value, isRichText, className }) => {
  const { t } = useTranslation();
  const { handleEdit } = useSchedulesEvent();
  const edit = useCallback(() => handleEdit(true), [handleEdit]);

  const noBreaksValue = useMemo(() => removeBreaksAndSpaces(value), [value]);

  return (
    <Flex className={cnj(classes.textRoot, className)}>
      <Typography color="colorIconForth2" font="500" size={13} height={15}>
        {label}
      </Typography>
      {noBreaksValue ? (
        isRichText ? (
          <RichTextEditor value={value} readOnly style={classes.editor} />
        ) : (
          <Typography color="thirdText" mt={4} height={18} size={14}>
            {value}
          </Typography>
        )
      ) : (
        <BaseButton onClick={edit} className={classes.emptyButton}>
          <Typography
            color="gray_60"
            size={14}
            height={18}
            className={classes.emptyText}
          >
            {`${t('add')} ${label}`}
          </Typography>
        </BaseButton>
      )}
    </Flex>
  );
};

export default Text;
