import classes from './MeetingToolOption.module.scss';

import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import React from 'react';
import LOBOX from '@shared/svg/Lobox';
import GOOGLE from '@shared/svg/GoogleMeet';
import ZOOM from '@shared/svg/Zoom';
import TEAMS from '@shared/svg/MicrosoftTeams';
import WEBEX from '@shared/svg/Webex';
import { schedulesDb } from '@lobox/utils';
import { UnArray } from '@shared/types/schedules';
import { useTranslation } from '@lobox/utils';

const meetingToolsValues = schedulesDb.meetingTools.map((item) => item.value);
type MeetingTool = UnArray<typeof meetingToolsValues>;

export const provderIconNameMapper: Record<MeetingTool, React.FC> = {
  LOBOX,
  GOOGLE,
  ZOOM,
  TEAMS,
  WEBEX,
};

export const meetingToolNameMapper: Record<MeetingTool, string> = {
  LOBOX: 'lobox',
  GOOGLE: 'google_meet',
  ZOOM: 'zoom',
  TEAMS: 'microsoft_teams',
  WEBEX: 'webex',
};

type Props = IconProps & { isSelected?: boolean };

type IconProps = {
  type: MeetingTool;
  isOption?: boolean;
  isLarge?: boolean;
};

export const MeetingToolIcon: React.FC<IconProps> = ({ type, isLarge }) => {
  const Icon = provderIconNameMapper[type];
  return (
    <Flex className={cnj(classes.wrapper, isLarge && classes.largeIcon)}>
      <Icon />
    </Flex>
  );
};

export const MeetingToolOption: React.FC<Props> = ({
  type,
  isSelected,
  size,
}) => {
  const { t } = useTranslation();
  const name = t(meetingToolNameMapper?.[type]);
  return (
    <Flex
      className={cnj(
        classes.itemWrapper,
        isSelected && classes.itemWrapperSelected
      )}
    >
      <MeetingToolIcon type={type} size={size} />
      <Typography
        color={isSelected ? 'white' : 'primaryText'}
        size={15}
        height={18}
        font="700"
        isTruncated
      >
        {name}
      </Typography>
    </Flex>
  );
};
