import classes from './ScheduleTaskDetails.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import { formatDate, useTranslation } from '@lobox/utils';
import AdvancedAvatarCard from '@shared/components/molecules/AdvancedAvatarCard';
import { Time } from '@shared/utils/Time';
import Text from './Text';
import DividerHorizontal from './DividerHorizontal';
import Collaborators from './Collaborators';
import Actions from './Actions';
import Attachments from './Attachments';
import useGetCurrentTimeZone from '@shared/hooks/api-hook/useGetCurrentTimeZone';

const ScheduleTaskDetails: React.FC = () => {
  const { t } = useTranslation();
  const { event, hasEditAccess } = useSchedulesEvent();

  const { data: userTimezone } = useGetCurrentTimeZone();
  const offsetDifference = event?.timezone?.offset
    ? Number(event?.timezone?.offset) - Number(userTimezone?.offset)
    : 0;
  const collaborators = [event?.creator, ...event?.collaborators];
  return (
    <Flex className={cnj(classes.scheduleMeetingDetailsRoot)}>
      <AdvancedAvatarCard
        firstText={event.creator?.fullName}
        secondText={event.creator?.usernameAtSign}
        thirdText={t('creator')}
        avatarProps={{ size: 'slg', imgSrc: event.creator?.croppedImageUrl }}
      />
      <DividerHorizontal />
      <Text
        className={classes.zeroMarginTop}
        label={t('task_title')}
        value={event.title}
      />
      <Flex flexDir="row">
        <Text
          label={t('start_date')}
          value={
            event.startTime
              ? formatDate(
                  Time.getTimeWithOffset(event.startTime, offsetDifference)
                )
              : ''
          }
        />
        <DividerVertical className={classes.divider} />
        <Text
          label={t('start_time')}
          value={
            Time.getFormTime(
              Time.getTimeWithOffset(event?.startTime, offsetDifference)
            )?.label
          }
        />
      </Flex>
      <Flex flexDir="row">
        <Text
          label={t('end_date')}
          value={
            event.endTime
              ? formatDate(
                  Time.getTimeWithOffset(event.endTime, offsetDifference)
                )
              : ''
          }
        />
        <DividerVertical className={classes.divider} />
        <Text
          label={t('end_time')}
          value={
            Time.getFormTime(
              Time.getTimeWithOffset(event?.endTime, offsetDifference)
            )?.label
          }
        />
      </Flex>
      {/* <Text label={t('repeat')} value="In progress" /> */}
      <DividerHorizontal />
      {event.description && (
        <>
          <Text
            className={classes.zeroMarginTop}
            label={t('description')}
            value={event.description}
            isRichText
          />
          <DividerHorizontal />
        </>
      )}

      {event.category && (
        <>
          <Text
            className={classes.zeroMarginTop}
            label={t('category')}
            value={event.category?.label}
          />
          <DividerHorizontal />
        </>
      )}

      <Collaborators value={collaborators} labelKey="collaborators" />

      {event?.attachmentFiles && (
        <Attachments
          value={event?.attachmentFiles}
          className={classes.zeroMarginTop}
        />
      )}

      {hasEditAccess && <DividerHorizontal />}
      <Actions event={event} />
    </Flex>
  );
};

export default ScheduleTaskDetails;
