import React, { Profiler as RJSProfiler } from 'react';
import type {
  ProfilerProps as RJSProfilerProps,
  ProfilerOnRenderCallback,
} from 'react';

export interface ProfilerProps extends Omit<RJSProfilerProps, 'onRender'> {
  fileName?: string;
  onRender?: ProfilerOnRenderCallback;
}

const noOp = () => {};

const Profiler = ({
  id,
  fileName,
  onRender = noOp,
  children,
}: ProfilerProps): JSX.Element => {
  if (fileName) {
    console.warn(
      `We will store the onRender's data in a csv file by ${fileName}_${id}`
    );
  }

  return process.env.NODE_ENV === 'production' ? (
    <>{children}</>
  ) : (
    <RJSProfiler id={id} onRender={onRender}>
      {children}
    </RJSProfiler>
  );
};

export default Profiler;
