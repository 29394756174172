import * as React from 'react';
const Lobox: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#5384EE"
      d="M10 15.258V18H2.169v-2.742H10ZM3.205 0v18H0V0h3.205Z"
    />
  </svg>
);
export default Lobox;
