import classes from './PostCreator.component.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import {
  detectNewHashtags,
  getRichTextMentions,
  sanitizeEmojies,
  getRichTextHashtags,
} from '@lobox/uikit/RichText/richTextApi';
import useToast from '@lobox/uikit/Toast/useToast';
import {
  QueryKeys,
  postApi,
  publishToMyProfile,
  removeRichTextBreakLines,
  useGlobalState,
  useGlobalDispatch,
  useReactMutation,
  routeNames,
  useTranslation,
  getVideoDuration,
} from '@lobox/utils';
import sum from 'lodash/sum';
import map from 'lodash/map';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from '@lobox/utils';
import useFileUpload from '@shared/hooks/api-hook/useFileUpload';
import { useBeforeUnload } from '@shared/hooks';
import type { CreatePostProps } from '@lobox/utils';
import useNavigateSearchPage from '@shared/hooks/useNavigateSearchPage';

type Position = { x: number; y: number };
type Ratio = '0:0' | '1:1' | '4:5' | '16:9';
type Size = { width: number; height: number };
type ReadyToUpload = {
  file: Blob;
  original: {
    type: string;
    file: File;
  };
  position: Position;
  ratio: Ratio;
  zoom: number;
  fileSize: string;
  duration: number;
  size: Size;
  realSize: Size;
  backgroundColor: string;
  secondaryLink: string;
};
type ReadyToPostFeed = {
  postIt?: boolean;
  isEditMode?: boolean;
  updatePostId?: number;
  values?: any;
  richTextInput?: string;
  isVisibleMapView?: boolean;
  selectedHighlight?: any;
  selectedLocation?: any;
  selectedUsers?: any;
  attachment?: any;
  pathname?: string;
  readyToUploadFiles?: ReadyToUpload[];
  files?: any;
};
interface ReadyToPost extends ReadyToUpload {
  link: string;
  type: 'video' | 'image';
}
interface Props {
  paperClassName?: string;
  showProgressBar?: boolean;
}

const PostCreator = (): JSX.Element => {
  const uploadedFilesDataRef = React.useRef<ReadyToPost[]>([]);

  const queryClient = useQueryClient();
  const appDispatch = useGlobalDispatch();
  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation();
  const { upload } = useFileUpload();
  const { isOpenModal } = useGlobalState('createPostModal');
  const uploadingPostFiles = useGlobalState('uploadingPostFiles');
  const createPostProgress = useGlobalState('createPostProgress');

  const readyToPostFeed: ReadyToPostFeed =
    queryClient.getQueryData([
      QueryKeys.readyToPostFeed,
      'READY_TO_POST_FEED',
    ]) || {};
  const { mutate: createPost } = useReactMutation({
    apiFunc: postApi.createPost,
  });
  const { mutate: publishToProfile } = useReactMutation({
    apiFunc: publishToMyProfile,
  });
  const { mutate: editPost } = useReactMutation({
    apiFunc: postApi.editPost,
  });
  const {
    readyToUploadFiles,
    files,
    isEditMode,
    updatePostId,
    values,
    richTextInput,
    isVisibleMapView,
    selectedHighlight,
    selectedLocation,
    selectedUsers,
    attachment,
    pathname,
  } = readyToPostFeed;
  const feedSubmitted = Boolean(readyToPostFeed?.postIt && !isOpenModal);
  const postUploadProgressIndex = readyToUploadFiles?.length || 0;
  const progress = sum(createPostProgress) / (postUploadProgressIndex + 1); // +1 for post data

  const setCreatePostProgress = (value: number, index: number) => {
    appDispatch({
      type: 'SET_CREATE_POST_PROGRESS',
      payload: { value, index },
    });
  };

  const resetCreatePostProgress = () => {
    appDispatch({ type: 'RESET_CREATE_POST_PROGRESS' });
  };
  const navigateSearchPage = useNavigateSearchPage();

  const viewPostHandler = (postId: string) => () => {
    navigateSearchPage({
      pathname: routeNames.searchPosts,
      currentEntityId: postId,
    });
  };

  const showToast = (data: any) => {
    setCreatePostProgress(100, postUploadProgressIndex);
    toast(data);
  };

  const resetReadyToPostFeed = () => {
    queryClient.setQueryData(
      [QueryKeys.readyToPostFeed, 'READY_TO_POST_FEED'],
      {}
    );
  };

  const showError = (error?: any) => {
    const rateLimit =
      error?.response?.data?.error === 'RateLimiterExceedException';
    showToast({
      type: 'error',
      icon: 'times-circle',
      message: rateLimit
        ? t('limit_rate_unable_post')
        : t('something_went_wrong'),
    });
    resetReadyToPostFeed();
    uploadedFilesDataRef.current = [];
    resetCreatePostProgress();
  };

  const makePost = (hasHighlight: boolean, profileEntityId?: string) => {
    const _richTextInput = detectNewHashtags(richTextInput || '');
    const mentions = getRichTextMentions(_richTextInput);
    const hashtags = getRichTextHashtags(_richTextInput);
    const text = sanitizeEmojies(removeRichTextBreakLines(_richTextInput));

    const mapHighlight = (id, formValues) => {
      const transformedHighlightValues =
        selectedHighlight?.form?.highlightTransform?.(formValues);

      return {
        ...(transformedHighlightValues || {}),
        profileEntityId: id,
      };
    };
    const medias = uploadedFilesDataRef.current.length
      ? map(
          uploadedFilesDataRef.current,
          ({
            type,
            size,
            realSize,
            ratio,
            link,
            position,
            zoom,
            backgroundColor,
            fileSize,
            duration,
            secondaryLink: secondaryUrl,
          }) => {
            const box = `bw=${size.width}&bh=${size.height}`;
            const rsz = `rw=${realSize.width}&rh=${realSize.height}`;
            const rat = `r=${ratio}`;
            const pos = `px=${position.x}&py=${position.y}`;
            const zom = `z=${zoom}`;
            const bkc = `bc=${backgroundColor.slice(1)}`;
            const fs = `fs=${fileSize}`;
            const du = `du=${duration}`;
            const url = `${link}?${box}&${rsz}&${rat}&${pos}&${zom}&${bkc}&${fs}&${du}`;

            return {
              type,
              url,
              secondaryUrl,
            };
          }
        )
      : map(files, ({ type, url }) => ({ type, url }));
    const post: CreatePostProps = {
      body: text,
      highlight: hasHighlight
        ? mapHighlight(profileEntityId, values)
        : undefined,
      location:
        selectedLocation?.position?.lat && selectedLocation?.position?.lon
          ? {
              detail: selectedLocation?.vicinity,
              preview: isVisibleMapView,
              lat: selectedLocation?.position?.lat,
              lon: selectedLocation?.position?.lon,
              title: selectedLocation?.title,
              cityCode: selectedLocation?.cityCode,
              stateCode: selectedLocation?.stateCode,
              countryCode: selectedLocation?.countryCode,
              cityName: selectedLocation?.city,
              countryName: selectedLocation?.country,
            }
          : undefined,
      medias,
      tags: selectedUsers?.map((item) => ({
        userId: item.id,
        userType: item.type,
      })),
      hashtags,
      mentions,
      sharedProfileId:
        attachment?.type === 'profile' ? attachment?.data?.id : undefined,
      sharedPageId:
        attachment?.type === 'page' ? attachment?.data?.id : undefined,
      sharedJobId:
        attachment?.type === 'job' ? attachment?.data?.id : undefined,
      sharedOfId:
        (attachment?.type === 'post' &&
          (attachment?.data?.post.sharedOf?.id || attachment?.data?.post.id)) ||
        undefined,
    };

    if (selectedHighlight?.form?.postOnSuccess) {
      selectedHighlight?.form?.postOnSuccess?.(values);
    }

    if (!isEditMode) {
      createPost(
        { postData: post },
        {
          onSuccess: (data) => {
            appDispatch({
              type: 'SET_CREATED_POST_DATA',
              payload: { sender: pathname, data },
            });
            showToast({
              type: 'success',
              icon: 'check-circle',
              message: t('post_add_success'),
              actionButton: () => (
                <Button
                  onClick={viewPostHandler(data.id)}
                  schema="ghost-brand"
                  className={classes.actionBtn}
                  label={t('view_post')}
                  variant="text"
                />
              ),
            });
            if (selectedHighlight?.postOnSuccess) {
              selectedHighlight?.postOnSuccess?.(values);
            }
          },
          onError: () => {
            showError();
          },
        }
      );
    } else {
      editPost(
        { id: updatePostId, postData: post },
        {
          onSuccess: (data) => {
            appDispatch({
              type: 'SET_CREATED_POST_DATA',
              payload: { sender: pathname, data },
            });
            showToast({
              type: 'success',
              icon: 'check-circle',
              message: t('post_update_success'),
            });
          },
          onError: () => {
            showError();
          },
        }
      );
    }
  };

  const doPost = () => {
    setCreatePostProgress(75, postUploadProgressIndex);

    if (selectedHighlight) {
      if (values && values.isPublishedProfile) {
        const transformedData =
          selectedHighlight.form?.transform?.(values) || values;
        publishToProfile(
          {
            url: selectedHighlight.form?.url,
            formData: transformedData,
          },
          {
            onSuccess: (data) => {
              makePost(true, data?.id);
            },
          }
        );
      } else {
        makePost(true);
      }
    } else {
      makePost(false);
    }
  };

  const uploadFiles = async (index: number) => {
    const file = readyToUploadFiles?.[index];
    if (!file) {
      doPost();
      return;
    }

    const type = file?.original?.type;
    const isImage = type === 'image';

    const duration =
      type === 'video'
        ? await getVideoDuration(file.original?.file)
        : undefined;

    appDispatch({
      type: 'SET_UPLOADING_POST_FILES',
      payload: true,
    });

    upload(
      {
        file: file.file,
        multiSizeImage: true,
        onProgress: (prg: number) => {
          setCreatePostProgress(prg * (isImage ? 0.3 : 0.9), index);
        },
      },
      {
        onSuccess: (data: any) => {
          uploadedFilesDataRef.current[index] = {
            duration: duration || undefined,
            type,
            ...file,
            ...data?.data,
          };
          if (isImage) {
            // upload original image
            upload(
              {
                file: file.original.file,
                multiSizeImage: true,
                onProgress: (origPrg: number) => {
                  setCreatePostProgress(30 + origPrg * 0.6, index);
                },
              },
              {
                onSuccess: (origData: any) => {
                  uploadedFilesDataRef.current[index] = {
                    ...uploadedFilesDataRef.current[index],
                    secondaryLink: origData?.data?.link,
                  };
                  setCreatePostProgress(100, index);
                  uploadFiles(index + 1);
                },
                onError: (error: any) => {
                  showError(error);
                },
              }
            );
            return;
          }
          setCreatePostProgress(100, index);
          uploadFiles(index + 1);
        },
        onError: (error: any) => {
          showError(error);
        },
      }
    );
  };

  React.useEffect(() => {
    if (uploadingPostFiles) return;
    if (feedSubmitted) {
      appDispatch({ type: 'CLOSE_GLOBAL_ENTITY_MODAL' });
      // Show something when we start the first upload
      setCreatePostProgress(10, 0);
      uploadFiles(0);
    }
  }, [feedSubmitted, uploadingPostFiles]);

  React.useEffect(() => {
    if (progress !== 100) {
      return;
    }

    resetReadyToPostFeed();
    uploadedFilesDataRef.current = [];
    resetCreatePostProgress();
  }, [progress]);

  const handleBeforeUnload = (evt: Event) => {
    if (!feedSubmitted) return;
    evt.preventDefault();
    // Included for legacy support, e.g. Chrome/Edge < 119
    evt.returnValue = true;
  };

  useBeforeUnload(handleBeforeUnload, [feedSubmitted]);

  return <></>;
};

export default PostCreator;
