import classes from './MeetingTool.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import {
  MeetingToolIcon,
  meetingToolNameMapper,
} from '../../ScheduleCreationForm/partials/MeetingToolOption';

export interface MeetingToolProps {
  className?: string;
  value: {
    label: string;
    value: string;
  };
}

const MeetingTool: React.FC<MeetingToolProps> = ({
  value: _value,
  className,
}) => {
  const { value } = _value || {};
  const { t } = useTranslation();
  const label = meetingToolNameMapper[value];

  return (
    <Flex className={cnj(classes.textRoot, className)}>
      <Flex flexDir="row">
        <MeetingToolIcon type={value} isLarge />
        <Flex>
          <Typography
            color="secondaryDisabledText"
            font="400"
            size={12}
            height={14}
          >
            {t('meeting_tool')}
          </Typography>
          <Flex className={classes.filler} />
          <Typography
            font="700"
            mt={4}
            height={18.75}
            size={16}
            color="smoke_coal"
          >
            {label}
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MeetingTool;
