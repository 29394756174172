/* eslint-disable react-hooks/exhaustive-deps */
import {
  schedulesApi,
  useReactMutation,
  useReactQuery,
  useTranslation,
} from '@lobox/utils';
import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import FilePicker from '../FilePicker';
import useTheme from '../utils/useTheme';
import Button from '../Button/Button.component';
import Flex from '../Flex/Flex.component';
import cnj from '../utils/cnj';
import AttachmentItem from '../AttachmentItem';
import classes from './AttachmentPicker.component.module.scss';

export interface UploadedFile {
  file: File;
  id: string;
}
interface AttachmentPickerProps {
  value: Value[];
  onChange: (newValue: File[]) => void;
  className?: string;
  wrapperClassName?: string;
  uploadApi?: typeof schedulesApi.uploadFile;
  removeApi?: typeof schedulesApi.deleteFile;
  getApi?: typeof schedulesApi.getFile;
  readonly?: boolean;
}

interface Value {
  id: string;
  filename: string;
  type: string;
}

const AttachmentPicker: FC<AttachmentPickerProps> = (props) => {
  const {
    onChange,
    className = '',
    wrapperClassName = '',
    value,
    uploadApi,
    removeApi,
    getApi,
    readonly,
  } = props;
  const ref = useRef(0);
  ref.current += 1;

  const { isDark } = useTheme();
  const { t } = useTranslation();

  const [files, setFiles] = useState<UploadedFile[]>([]);

  const { mutate: upload } = useReactMutation({
    apiFunc: uploadApi,
  });
  const { mutate: remove } = useReactMutation({
    apiFunc: removeApi,
  });
  useReactQuery({
    action: {
      key: value?.map((file) => file?.id + file?.filename + file?.type),
      apiFunc: async () => {
        const _files = await Promise.all(
          value?.map(
            (_file) =>
              new Promise(async (resolve) => {
                try {
                  const binaryData = await getApi(
                    { id: _file?.id },
                    { responseType: 'arraybuffer' }
                  );

                  const newFile = new File([binaryData], _file?.filename, {
                    type: _file?.type,
                  });
                  resolve({ id: _file?.id, file: newFile });
                } catch (err) {
                  resolve(false);
                }
              })
          )
        );
        const _files_ = _files?.filter(Boolean);
        setFiles(_files_);
        onChange(
          _files_?.map((item) => ({
            id: item?.id,
            filename: item?.file?.name,
            type: item?.file?.type,
          }))
        );
      },
    },
    config: {
      enabled: value?.some(
        (val) => !files?.find((file) => file?.id === val?.id)
      ),
    },
  });

  const onFilePicked = (file: File | File[]) => {
    const newFiles = Array.isArray(file) ? file : [file];

    Promise.all(
      newFiles.map((file) => {
        return new Promise((resolve) => {
          upload(
            { file },
            {
              onSuccess: (res) => {
                resolve({ file, id: res?.id as unknwon as string });
              },
            }
          );
        });
      })
    )
      .then((_files) => {
        const _files_ = [...files, ..._files];
        onChange(
          _files_?.map((item) => ({
            id: item?.id,
            filename: item?.file?.name,
            type: item?.file?.type,
          }))
        );
        setFiles(_files_);
      })
      .catch(console.log);
  };

  const onRemoveAttachment = (selectedFile: UploadedFile) => {
    const onSuccess = () => {
      const _files_ = files.filter((file) => file?.id !== selectedFile?.id);
      setFiles(_files_);
      onChange(
        _files_?.map((item) => ({
          id: item?.id,
          filename: item?.file?.name,
          type: item?.file?.type,
        }))
      );
    };

    if ('id' in selectedFile) {
      remove(
        { id: selectedFile?.id },
        {
          onSuccess,
        }
      );
    } else {
      onSuccess();
    }
  };

  return (
    <Flex className={cnj(classes.attachmentPickerRoot, className)}>
      <div className={cnj(classes.attachmentWrapper, wrapperClassName)}>
        {files.map((file) => {
          const isLoaded = !!file?.file;
          if (!isLoaded) return null;
          return (
            <AttachmentItem
              key={file?.id}
              attachment={file}
              onRemove={onRemoveAttachment}
              readonly={readonly}
            />
          );
        })}
      </div>
      {!!files?.length && !readonly && <Flex className={classes.filler} />}
      {!readonly && (
        <FilePicker
          isMulti
          type="messageInput"
          onFilePicked={onFilePicked}
          buttonComponent={
            <Button
              schema={isDark ? 'dark-gray' : 'light-gray'}
              leftIcon="paperclip"
              leftType="fas"
              label={t('add_attachments')}
            />
          }
        />
      )}
    </Flex>
  );
};

export default AttachmentPicker;
