import classes from './ScheduleEventDetailsModal.component.module.scss';

import React, { useMemo } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Spinner from '@lobox/uikit/Spinner';
import {
  ModalBody,
  ModalFooter,
  ModalHeaderSimple,
} from '@shared/components/molecules';
import { FixedRightSideModalDialog } from '@shared/components/molecules/Modal';
import {
  schedulesEventValues,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import { motion } from 'framer-motion';
import useSchedulesEvent from '@shared/hooks/useSchedulesEvent';
import { useScheduleState } from '@/shared/contexts/Schedule/schedule.provider';
import getSchedulesSectionsQueryKey from '@shared/utils/getSchedulesSectionsQueryKey';
import ScheduleDetailsModalContent from './ScheduleEventDetailsModal.content';
import CelebrationForm from './partials/CelebrationForm';
import { TOP_BAR_NOTIFICATION_BUTTON } from '@shared/constants/enums';
import ScheduleDetailsModalFooter from './ScheduleEventDetailsModal.footer';
import Typography from '@shared/uikit/Typography';

type Props = {};

const ScheduleEventDetailsModal: React.FC<Props> = () => {
  const { t } = useTranslation();

  const { schedulesEventType, closeHandler, queryResult, event } =
    useSchedulesEvent();
  const { upComingQueryKey } = getSchedulesSectionsQueryKey(schedulesEventType);
  useUpdateInfinityData(upComingQueryKey);

  const scheduleCreationModalDataState = useScheduleState(
    'scheduleCreationModalData'
  );
  const isFromNotification = scheduleCreationModalDataState?.isFromNotification;
  const noCloseButton = isFromNotification;

  const titles = {
    [schedulesEventValues.MEETING]: t('meeting_details'),
    [schedulesEventValues.REMINDER]: t('reminder_details'),
    [schedulesEventValues.TASK]: t('task_details'),
    [schedulesEventValues.HOLIDAY]: t('event_details'),
    [schedulesEventValues.BIRTHDAY]: t('birthday_details'),
  };

  const isHolidayOrBirthday = [
    schedulesEventValues.HOLIDAY,
    schedulesEventValues.BIRTHDAY,
  ].includes(schedulesEventType);

  const onBack = () => {
    if (!isFromNotification) return;
    closeHandler();
    document?.getElementById(TOP_BAR_NOTIFICATION_BUTTON)?.click?.();
  };

  const hasFooter = useMemo(
    () =>
      [
        schedulesEventValues.MEETING,
        schedulesEventValues.TASK,
        schedulesEventValues.REMINDER,
      ].includes(event?.schedulesEventType),
    [event]
  );

  return (
    <FixedRightSideModalDialog onBack={closeHandler} onClose={closeHandler}>
      <ModalHeaderSimple
        closeButtonProps={{ onClick: closeHandler }}
        visibleHeaderDivider
        title={titles[schedulesEventType]}
        className={classes.largeModalHeader}
        hideBack={!noCloseButton}
        backButtonProps={{
          onClick: closeHandler,
        }}
        noCloseButton={noCloseButton}
      />
      {queryResult?.isLoading ? (
        <Spinner />
      ) : (
        <>
          <ModalBody
            className={cnj(
              classes.modalBody,
              isHolidayOrBirthday && classes.holidayBody
            )}
          >
            <motion.div
              initial="visible"
              animate="hidden"
              variants={{
                visible: { originX: 0, opacity: 0, scale: 0.95 },
                hidden: { originX: 0.5, opacity: 1, scale: 1 },
              }}
              transition={{ duration: 0.2 }}
            >
              {!event ? (
                <Typography color="smoke_coal">Event does not exist</Typography>
              ) : (
                <ScheduleDetailsModalContent />
              )}
            </motion.div>
          </ModalBody>
          {isHolidayOrBirthday && (
            <CelebrationForm
              closeHandler={closeHandler}
              event={event}
              classNames={{ container: classes.celebrationFormContainer }}
              schedulesEventType={schedulesEventType}
            />
          )}
          {hasFooter && (
            <ModalFooter className={classes.footer}>
              <ScheduleDetailsModalFooter event={event} />
            </ModalFooter>
          )}
        </>
      )}
    </FixedRightSideModalDialog>
  );
};

export default ScheduleEventDetailsModal;
