import React from 'react';
import { useTranslation } from '@lobox/utils';
import AvatarsCard from '@shared/components/molecules/AvatarsCard';

export interface AvatarsProps {
  className?: string;
  avatars: Array<{ id: string; croppedImageUrl: string }>;
  onClick: () => void;
}

const limit = 8;

const Avatars: React.FC<AvatarsProps> = ({ onClick, avatars, className }) => {
  const { t } = useTranslation();
  const visibleAvatars = avatars.slice(0, limit);
  const remainCount =
    avatars?.length > limit ? avatars?.length - limit : undefined;

  return (
    <AvatarsCard
      onClick={onClick}
      className={className}
      text={remainCount ? `+${remainCount} ${t('more')}` : undefined}
      avatars={visibleAvatars}
    />
  );
};

export default Avatars;
