import classes from './DividerHorizontal.module.scss';

import React from 'react';
import Divider from '@lobox/uikit/Divider';

export interface DividerHorizontalProps {
  className?: string;
}

const DividerHorizontal: React.FC<DividerHorizontalProps> = () => {
  return <Divider className={classes.divider} />;
};

export default DividerHorizontal;
